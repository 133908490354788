import styled from 'styled-components';

import { flexGap } from '@/shared/lib/helpers/flex-gag-polyfill.helper';
import { Box } from '@/shared/ui/box';

export const NavigationWrapper = styled(Box)`
	flex-direction: column;
	${flexGap('16px', { direction: 'column' })};
	margin-top: 16px;
`;

import { lazy } from 'react';

import { withSuspense } from '@/shared/lib/hocs/with-suspense.hoc';

import { ChangeThemeSpinner } from '../change-theme-spinner';

export const ChangeTheme = withSuspense(
	lazy(() => import('./change-theme.component')),
	ChangeThemeSpinner,
);

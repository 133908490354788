import { boatServiceApi } from '@/shared/api/boat-service.api';
import { AppStoreTag } from '@/shared/constants/app-store-tag.constant';

import { BoatVariables, BoatVariablesResponse } from '../model/types/historical-data.interface';
import { parseBoatVariables } from '../model/utils/parse-boat-variables.util';

const historicalDataBoatVariablesApi = boatServiceApi.injectEndpoints({
	endpoints: (build) => ({
		getBoatVariables: build.query<BoatVariables, string>({
			providesTags: [AppStoreTag.Historical],
			query: (uuid) => ({
				method: 'GET',
				url: `boat/${uuid}/vars-sorted`,
			}),
			transformResponse: (response: BoatVariablesResponse): BoatVariables => parseBoatVariables(response),
		}),
	}),
});

export const { useGetBoatVariablesQuery } = historicalDataBoatVariablesApi;

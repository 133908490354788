import { memo } from 'react';

import { Box } from '@/shared/ui/box';
import { Skeleton } from '@/shared/ui/skeleton';

const ChangeThemeSpinnerComponent = () => {
	return (
		<Box marginRight="8px">
			<Skeleton width="60px" height="32px" radius="130px" />
		</Box>
	);
};

export default memo(ChangeThemeSpinnerComponent);

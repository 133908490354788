import styled from 'styled-components';

import { Box } from '@/shared/ui/box';
import { Caption } from '@/shared/ui/caption';
import { Icon } from '@/shared/ui/icon';

import { Toast } from '../../model/types/toast.interface';

export const Wrapper = styled(Box)<Pick<Toast, 'type'>>`
	box-shadow: 0px 4px 4px rgba(0, 32, 51, 0.04), 0px 8px 24px rgba(0, 32, 51, 0.12);
	border-radius: 2px;
	width: 335px;
	background: ${({ theme: { toast } }) => toast.background};
	border: 1px solid ${({ theme: { colors }, type }) => colors[type]};
	padding: 16px;
	cursor: pointer;
	justify-content: space-between;
`;

export const Content = styled(Box)`
	flex: 1;
`;

export const ToastIcon = styled(Icon)``;

export const CloseIcon = styled(Icon)`
	margin-left: auto;
`;

export const List = styled(Caption)`
	display: inline-block;
	width: 240px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

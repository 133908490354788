import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import WarningSvg from '@/shared/assets/icons/toast/warning.svg';
import CloseSvg from '@/shared/assets/icons/ui-kit/close-modal.svg';
import { Box } from '@/shared/ui/box';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icon';
import { Text } from '@/shared/ui/text';

import { createModalHook } from '../../lib/create-modal-hook.helper';
import * as Styled from './common-modal.styles';
import { CommonModalProps } from './common-modal.types';

export const CommonModalComponent = ({
	title,
	description,
	okText,
	cancelText = 'Cancel',
	onClose,
	onConfirm,
	children,
	content,
	isLoading,
	disabled,
}: CommonModalProps) => {
	const { t } = useTranslation('common');
	const { modal } = useTheme();

	return (
		<Styled.Wrapper direction="column">
			<Box align="center" padding="16px" justify="space-between" borderBottom={modal.border}>
				<Text label={title} weight={600} />
				<Styled.CloseIcon Svg={CloseSvg} onClick={onClose} />
			</Box>
			{Boolean(children) ? (
				<Box padding="16px" flex={1} justify="center">
					{children}
				</Box>
			) : (
				<Styled.Content align="center" justify="center" direction="column" padding="16px" gap={12}>
					<Icon Svg={WarningSvg} />
					<Styled.Description label={description ?? ''} align="center" />
					{content}
				</Styled.Content>
			)}
			<Box gap={16} padding="16px" borderTop={modal.border}>
				<Button
					label={okText || t('modal.button.ok')}
					onClick={onConfirm ?? onClose}
					size="full"
					isLoading={isLoading}
					disabled={disabled}
				/>
				<Button label={t('modal.button.cancel') || cancelText} variant="outlined" onClick={onClose} size="full" />
			</Box>
		</Styled.Wrapper>
	);
};

export default createModalHook<CommonModalProps>((props) => () => <CommonModalComponent {...props} />);

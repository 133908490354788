import { useEffect } from 'react';

import { useUserActions } from '@/entities/user';
import { useIsAuth, useIsInited } from '@/entities/user/model/selectors/select-user';
import { AppStorage } from '@/shared/constants/app-storage.constant';
import { PageLoader } from '@/widgets/page';

import { useUserInitQuery } from '../../api/user.api';
import { UserGateProps } from './user-gate.types';

const UserGateComponent = ({ children }: UserGateProps) => {
	const isAuth = useIsAuth();
	const isInited = useIsInited();
	const { setInited } = useUserActions();
	const hasToken = localStorage.getItem(AppStorage.Token);
	const { isLoading } = useUserInitQuery(null, { skip: isAuth || !hasToken });

	useEffect(() => {
		if (!hasToken) {
			setInited(true);
		}
	}, [hasToken, setInited]);

	if (isLoading || !isInited) {
		return <PageLoader />;
	}

	return <>{children(isAuth)}</>;
};

export default UserGateComponent;

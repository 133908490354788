import { useEffect } from 'react';

type KeyType = 'Escape';

type UseKeyDownHook = (action: () => void, key?: KeyType) => void;

export const useKeyDown: UseKeyDownHook = (action, key = 'Escape') => {
	useEffect(() => {
		const onKeyDown = (e: KeyboardEvent) => {
			if (e.key === key) {
				action();
			}
		};

		window.addEventListener('keydown', onKeyDown);

		return () => {
			window.removeEventListener('keydown', onKeyDown);
		};
	}, [action, key]);
};

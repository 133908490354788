import { Navigate } from 'react-router-dom';

import { useIsSelectBoat } from '@/entities/boat';
import { useRoutes } from '@/entities/navigation';
import { UserRole, useUserRole } from '@/entities/user';

const HomeDefaultComponent = () => {
	const isSingleBoatRole = useUserRole([UserRole.SingleBoat]);
	const isSelectedBoat = useIsSelectBoat();
	const { multiView, singleView } = useRoutes();

	if (isSingleBoatRole || isSelectedBoat) {
		return <Navigate to={singleView} />;
	}

	return <Navigate to={multiView} />;
};

export default HomeDefaultComponent;

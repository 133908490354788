import { Disclosure } from '@headlessui/react';
import styled from 'styled-components';

import { StyledProps } from '@/shared/lib/interfaces/styled-props.interface';
import { Box } from '@/shared/ui/box';

import { StyledOpen } from './accordion.types';

export const Button = styled(Disclosure.Button)`
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: none;
	border: none;
	outline: none;
`;

export const Panel = styled(Disclosure.Panel)<StyledOpen>`
	max-height: ${({ open }) => (open ? '100%' : '0')};
	overflow: ${({ open }) => (open ? 'initial' : 'hidden')};
	transition: all 0.2s ease-in-out;
`;

export const AccordionMenu = styled(Box)<StyledProps>`
	position: fixed;
	left: 0;
	top: ${({ theme: { layouts } }) => layouts.header.height};
	z-index: ${({ theme: { zIndex } }) => zIndex.menu};
	background-color: ${({ theme: { menu } }) => menu.background};
	width: 100%;
	min-height: ${({ open, theme: { layouts } }) => (open ? `calc(100% - ${layouts.header.height})` : '0')};
	max-height: ${({ open, theme: { layouts } }) => (open ? `calc(100% - ${layouts.header.height})` : '0')};
	overflow-y: ${({ open }) => (open ? 'scroll' : 'hidden')};
	transition: 0.2s ease-in-out;
`;

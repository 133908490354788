import { Namespace } from 'i18next';

import { useIsPlant } from '@/entities/user';

export const useNamespace = (type: Namespace, plant: 'plant' | 'helmetPlant' = 'plant') => {
	const isPlant = useIsPlant();

	if (isPlant) {
		return [plant, type];
	}

	return type;
};

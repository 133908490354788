import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { transformAttribute } from '@/shared/lib/helpers/transform-attribute.helper';
import { Icon } from '@/shared/ui/icon';
import { Text } from '@/shared/ui/text';

import * as Styled from './navigation-link.styles';
import { NavigationLinkProps } from './navigation-link.types';

const NavigationLinkComponent = ({ Svg, label, href, collapsed, close }: NavigationLinkProps) => {
	const { t } = useTranslation('common');

	return (
		<Styled.Link onClick={close} collapsed={transformAttribute(collapsed)} to={href}>
			<Icon Svg={Svg} />
			{!collapsed && <Text label={t(label)} weight={500} />}
		</Styled.Link>
	);
};

export default memo(NavigationLinkComponent);

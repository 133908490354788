import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@/shared/lib/helpers/create-store-logic';

import { Modal } from '../types/modal.interface';
import { ModalSchema } from '../types/modal.schema';

const initialState: ModalSchema = {
	data: [],
};

export const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		addModal: (state, action: PayloadAction<Modal>) => {
			state.data = [action.payload, ...state.data];
		},
		removeModal: (state, action: PayloadAction<string>) => {
			state.data = state.data.filter((modal) => modal.id !== action.payload);
		},
	},
});

export const { actions: modalActions, reducer: modalReducer, useActions: useModalActions } = modalSlice;

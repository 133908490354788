import styled from 'styled-components';

import { CaptionProps } from './caption.types';

export const Caption = styled.span<Required<Pick<CaptionProps, 'weight' | 'opacity' | 'color' | 'whiteSpace'>>>`
	font-weight: ${({ weight }) => weight};
	color: ${({ color, theme: { caption } }) => caption[color]};
	opacity: ${({ opacity }) => opacity};
	font-size: 12px;
	white-space: ${({ whiteSpace }) => whiteSpace};
`;

import { PropsWithChildren } from 'react';
import { useTheme } from 'styled-components';

import { Box } from '@/shared/ui/box';

const PageInnerWrapperComponent = ({ children }: PropsWithChildren) => {
	const { box } = useTheme();

	return (
		<Box flex={1} flexWrap="wrap" border={box.border}>
			{children}
		</Box>
	);
};

export default PageInnerWrapperComponent;

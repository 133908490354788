import { memo } from 'react';

import * as Styled from './text.styles';
import { TextProps } from './text.types';

const TextComponent = ({
	label,
	weight = 400,
	size = 's',
	color = 'primary',
	align = 'left',
	paragraph = '0',
	cursor = 'default',
	wordBreak = 'initial',
	endSection,
	...props
}: TextProps) => {
	return (
		<Styled.Text
			weight={weight}
			size={size}
			color={color}
			align={align}
			cursor={cursor}
			paragraph={paragraph}
			wordBreak={wordBreak}
			{...props}
		>
			{label}
			{endSection}
		</Styled.Text>
	);
};

export default memo(TextComponent);

import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { flexGap } from '@/shared/lib/helpers/flex-gag-polyfill.helper';
import { StyledCollapsed } from '@/widgets/sidebar/ui/sidebar/sidebar.types';

export const Link = styled(NavLink)<StyledCollapsed>`
	display: flex;
	justify-content: ${({ collapsed }) => collapsed && 'center'};
	${flexGap('8px')};
	padding: 4px;
	border-radius: 5px;
	transition: 0.2s;

	& > p {
		color: ${({ theme: { link } }) => link.color};
	}

	& > svg {
		fill: ${({ theme: { link } }) => link.color};
	}

	&:visited {
		background: transparent;

		& > p {
			color: ${({ theme: { link } }) => link.color};
		}

		& > svg {
			fill: ${({ theme: { link } }) => link.color};
		}
	}

	&:hover,
	&.active {
		background: ${({ theme: { link } }) => link.active.background};

		& > p {
			color: ${({ theme: { link } }) => link.active.color};
		}

		& > svg {
			fill: ${({ theme: { link } }) => link.active.color};
		}
	}
`;

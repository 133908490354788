import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { StoreSchema } from '@/app/providers/store';
import { AppStorage } from '@/shared/constants/app-storage.constant';

export const baseQuery = (apiUrl: string) =>
	fetchBaseQuery({
		baseUrl: apiUrl,
		prepareHeaders: (headers, { getState }) => {
			const storageToken = localStorage.getItem(AppStorage.AccessToken);
			const stateToken = (getState() as StoreSchema).user.accessToken;
			const token = stateToken || storageToken;

			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}

			return headers;
		},
	});

import { boatServiceApi } from '@/shared/api/boat-service.api';
import { userServiceApi } from '@/shared/api/user-service.api';
import { AppStoreTag } from '@/shared/constants/app-store-tag.constant';
import { Pagination, PaginationVariables } from '@/shared/lib/interfaces/pagination.interface';

import { PaginationUser, PaginationUserResponse } from '../model/types/users-management-api.interface';

const usersManagementApi = userServiceApi.injectEndpoints({
	endpoints: (build) => ({
		getUsers: build.query<Pagination<PaginationUser>, PaginationVariables>({
			providesTags: [AppStoreTag.Users],
			query: ({ page, size }) => ({
				method: 'GET',
				url: `user/list?page=${page}&size=${size}`,
			}),
			transformResponse: (response: Pagination<PaginationUserResponse>): Pagination<PaginationUser> => ({
				...response,
				items: response.items.map((data) => ({
					id: String(data.id),
					username: data.username,
					role: data.role,
					editDashboard: data.edit_dashboard || false,
					email: data.email,
				})),
			}),
		}),
		getUser: build.query<PaginationUser, unknown>({
			providesTags: [AppStoreTag.UserDetail],
			query: (id: string) => ({
				method: 'GET',
				url: `user/get/${id}`,
			}),
			transformResponse: (response: PaginationUserResponse): PaginationUser => ({
				id: String(response.id),
				username: response.username,
				role: response.role,
				editDashboard: response.edit_dashboard,
				email: response.email,
			}),
			async onQueryStarted(variables, { queryFulfilled, dispatch }) {
				try {
					await queryFulfilled;
					dispatch(boatServiceApi.util.invalidateTags([AppStoreTag.Boat, AppStoreTag.BoatDetail]));
				} catch {}
			},
		}),
	}),
});

export const { useGetUsersQuery, useGetUserQuery } = usersManagementApi;

import { useMemo } from 'react';

import { plantDarkTheme } from '@/app/theme/plant/dark-theme';
import { plantLightTheme } from '@/app/theme/plant/light-theme';
import { yachtDarkTheme } from '@/app/theme/yacht/dark-theme';
import { yachtLightTheme } from '@/app/theme/yacht/light-theme';
import { useIsPlant, useUserTheme } from '@/entities/user';
import { AppTheme } from '@/shared/constants/app-theme.constant';

export const useCurrentTheme = () => {
	const theme = useUserTheme();
	const isPlant = useIsPlant();

	return useMemo(() => {
		if (theme === AppTheme.Dark) {
			return isPlant ? plantDarkTheme : yachtDarkTheme;
		} else {
			return isPlant ? plantLightTheme : yachtLightTheme;
		}
	}, [theme, isPlant]);
};

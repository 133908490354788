import { PropsWithChildren, useMemo, useState } from 'react';

import { AppStorage } from '@/shared/constants/app-storage.constant';
import { TimezoneContext } from '@/shared/lib/contexts/timezone.context';

export const currentTimezone =
	localStorage.getItem(AppStorage.Timezone) ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

const TimezoneProviderComponent = ({ children }: PropsWithChildren) => {
	const [timezone, setTimezone] = useState(currentTimezone);

	const defaultValues = useMemo(
		() => ({
			timezone,
			setTimezone: (timezone: string) => {
				setTimezone(timezone);
				localStorage.setItem(AppStorage.Timezone, timezone);
			},
		}),
		[timezone],
	);

	return <TimezoneContext.Provider value={defaultValues}>{children}</TimezoneContext.Provider>;
};

export default TimezoneProviderComponent;

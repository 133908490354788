import { memo } from 'react';

import * as Styled from './skeleton.styles';
import { SkeletonProps } from './skeleton.types';

const SkeletonComponent = ({ width = '100%', height, ...props }: SkeletonProps) => {
	return <Styled.SkeletonWrapper width={width} height={height} {...props} />;
};

export default memo(SkeletonComponent);

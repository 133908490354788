import styled from 'styled-components';

import { Box } from '@/shared/ui/box';

export const Wrapper = styled(Box)`
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
`;

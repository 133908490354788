import styled from 'styled-components';

import { Box } from '@/shared/ui/box';
import { Breadcrumbs } from '@/shared/ui/breadcrumbs';
import { Title as SharedTitle } from '@/shared/ui/title';

import { StyledParagraph } from './page-wrapper.types';

export const Wrapper = styled.main<StyledParagraph>`
	flex: 1;
	padding: 32px 32px ${({ paragraph }) => paragraph};
	display: flex;
	flex-direction: column;
	overflow: ${({ overflow }) => overflow};

	@media ${({ theme: { media } }) => media.mobileAndLaptop} {
		padding: 32px 0 ${({ paragraph }) => paragraph};
	}

	@media ${({ theme: { media } }) => media.tabletAndMobile} {
		margin-top: ${({ theme: { layouts } }) => layouts.header.height};
		height: 100%;
	}

	@media ${({ theme: { media } }) => media.mobile} {
		padding: 20px 18px ${({ paragraph }) => paragraph};
	}
`;

export const Title = styled(SharedTitle)`
	margin-bottom: 32px;

	@media ${({ theme: { media } }) => media.mobileAndLaptop} {
		padding: 0 24px;
	}
`;

export const InnerWrapper = styled(Box)`
	${({ theme: { page } }) => ({ ...page.wrapper })}

	& > * {
		max-width: 1190px;
	}
`;

export const PageBreadCrumbs = styled(Breadcrumbs)`
	@media ${({ theme: { media } }) => media.mobileAndLaptop} {
		padding: 0 24px;
	}

	@media ${({ theme: { media } }) => media.mobile} {
		padding: 0;
	}
`;

import { timezoneFormat } from '@/shared/lib/helpers/timezone-format.helper';

export const convertTimezone = (utcTime: string, timezone: string, json = false) => {
	const withoutUtc = utcTime.replace('Z', '');

	const { offset } = timezoneFormat(timezone);
	const currentTime = new Date(withoutUtc);

	if (json) {
		const date = new Date(currentTime.getTime() + offset * 3600 * 1000);
		const hoursDiff = date.getHours() - date.getTimezoneOffset() / 60;

		date.setHours(hoursDiff);

		return date;
	}

	return new Date(currentTime.getTime() + offset * 3600 * 1000);
};

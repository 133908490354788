import { ComponentType, FC, useLayoutEffect } from 'react';

export const withChangedDom =
	<T extends {}>(Component: ComponentType<T>): FC<T> =>
	(props: T) => {
		useLayoutEffect(() => {
			const spinnerElement = document.getElementById('spinner');

			if (spinnerElement) {
				spinnerElement.remove();
			}
		}, []);

		return <Component {...props} />;
	};

import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

import { boatActions } from '@/entities/boat';
import { userActions } from '@/entities/user';
import { baseQuery } from '@/shared/api/common.api';
import { AppStorage } from '@/shared/constants/app-storage.constant';
import { StatusCodeError } from '@/shared/constants/status-code-error.constant';

export const baseQueryWithReauth: (apiUrl: string) => BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
	(apiUrl: string) => async (args, api, extraOptions) => {
		const result = await baseQuery(apiUrl)(args, api, extraOptions);
		const error = result?.error as { status: number; originalStatus?: number };
		if (
			error &&
			(error.status === StatusCodeError.Unauthorized || error.originalStatus === StatusCodeError.Unauthorized)
		) {
			const refreshResult = await baseQuery(USER_API_URL)(
				{ url: 'user/refresh', method: 'POST', body: { token: localStorage.getItem(AppStorage.Token) } },
				api,
				extraOptions,
			);

			if (refreshResult.data) {
				const response = refreshResult.data as { refresh_token: string; access_token: string };
				localStorage.setItem(AppStorage.Token, response.refresh_token);
				localStorage.setItem(AppStorage.AccessToken, response.access_token);
				api.dispatch(userActions.setAccessToken(response.access_token));

				return baseQuery(apiUrl)(args, api, extraOptions);
			} else {
				api.dispatch(userActions.logout());
				api.dispatch(boatActions.resetBoatById());
				await baseQuery(USER_API_URL)({ url: 'grafana/logout', method: 'POST' }, api, extraOptions);
			}
		}
		return result;
	};

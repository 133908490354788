import { boatServiceApi } from '@/shared/api/boat-service.api';
import yachtImage from '@/shared/assets/images/yacht.png';
import { AppStoreTag } from '@/shared/constants/app-store-tag.constant';
import { convertTimezone } from '@/shared/lib/helpers/convert-timezone.helper';
import { dateFormat } from '@/shared/lib/helpers/date-format.helper';
import { OptionList } from '@/shared/lib/interfaces/option.interface';
import { Pagination } from '@/shared/lib/interfaces/pagination.interface';

import { boatActions } from '../model/slice/boat.slice';
import { Boat, BoatSearch } from '../model/types/boat.interface';
import {
	BoatApiVariables,
	BoatResponse,
	BoatsApiVariables,
	ConnectedUsertoYachtVariables,
	GetBoatsSearchResponse,
} from '../model/types/boat-api.interface';

const boatApi = boatServiceApi.injectEndpoints({
	endpoints: (build) => ({
		getBoats: build.query<Pagination<Boat>, BoatsApiVariables>({
			providesTags: [AppStoreTag.Boat],
			query: ({ page, size }) => ({
				method: 'GET',
				url: `boat/?page=${page}&size=${size}`,
			}),
			transformResponse: (response: Pagination<BoatResponse>, _, { timezone }): Pagination<Boat> => ({
				...response,
				items: response.items.map((data) => ({
					country: data.country,
					name: data.name,
					description: data.description,
					boatType: data.boat_type,
					createdAt: dateFormat(convertTimezone(data.created_at, timezone)),
					updatedAt: dateFormat(convertTimezone(data.updated_at, timezone)),
					ownerId: String(data.owner_id),
					id: String(data.id),
					mmsi: data.mmsi,
					users: data.users.map((userId) => String(userId)),
					uuid: data.boat_uuid,
					plantAddress: data.plant_address,
				})),
			}),
		}),
		getBoat: build.query<Boat, BoatApiVariables>({
			providesTags: [AppStoreTag.BoatDetail],
			query: ({ id }) => ({
				method: 'GET',
				url: `boat/${id}/`,
			}),
			transformResponse: (response: BoatResponse, _, { timezone }): Boat => ({
				country: response.country,
				name: response.name,
				description: response.description,
				boatType: response.boat_type,
				createdAt: dateFormat(convertTimezone(response.created_at, timezone)),
				updatedAt: dateFormat(convertTimezone(response.updated_at, timezone)),
				ownerId: String(response.owner_id),
				id: String(response.id),
				users: response.users.map((userId) => String(userId)),
				mmsi: response.mmsi,
				uuid: response.boat_uuid,
				plantAddress: response.plant_address,
			}),
			async onQueryStarted(variables, { queryFulfilled, dispatch }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(boatActions.updateBoat(data));
				} catch {}
			},
		}),
		connectedUsersToYacht: build.query<OptionList, ConnectedUsertoYachtVariables>({
			query: ({ boatId }) => ({
				method: 'GET',
				url: `boat/${boatId}/`,
			}),
			transformResponse: (response: BoatResponse, _, { currentUserId }): OptionList =>
				response.users_with_username
					.filter(({ id }) => String(id) !== currentUserId)
					.map((user) => ({
						value: String(user.id),
						label: user.username,
					})),
		}),
		getUserBoats: build.query<BoatSearch[], string>({
			query: (id) => ({
				method: 'GET',
				url: `boat/me?user_id=${id}`,
			}),
			transformResponse: (response: GetBoatsSearchResponse[]): BoatSearch[] =>
				response.map((data) => ({
					id: String(data.id),
					mmsi: data.mmsi,
					name: data.name,
					uuid: data.boat_uuid,
					imageUrl: data?.image_url || yachtImage,
				})),
			async onQueryStarted(variables, { queryFulfilled, dispatch }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(boatActions.setBoats(data));
					if (data.length === 1) {
						dispatch(boatActions.setBoatById(data[0].id));
					}
				} catch {}
			},
		}),
	}),
});

export const { useGetBoatQuery, useGetUserBoatsQuery, useGetBoatsQuery, useConnectedUsersToYachtQuery } = boatApi;

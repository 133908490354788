import styled from 'styled-components';

import { TitleProps } from './title.types';

export const Title = styled.div<Required<Pick<TitleProps, 'tag' | 'color' | 'paragraph' | 'align'>>>`
	${({ tag, theme: { title } }) => ({ ...title[tag] })}
	color:${({ color, theme: { title } }) => title.colors[color]};
	margin-bottom: ${({ paragraph }) => paragraph};
	text-align: ${({ align }) => align};
`;

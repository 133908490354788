import styled from 'styled-components';

import { Box } from '@/shared/ui/box';

export const Wrapper = styled(Box)`
	cursor: pointer;
	align-items: center;
	fill: ${({ theme: { modal } }) => modal.color};
	color: ${({ theme: { modal } }) => modal.color};
	transition: ${({ theme: { transition } }) => transition.primary};

	&:hover {
		opacity: 0.8;
	}
`;

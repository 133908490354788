import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/shared/ui/button';
import { Text } from '@/shared/ui/text';

import * as Styled from './error.styles';
import { ErrorProps } from './error.types';

const ErrorComponent = ({ tag = 'div', message, hasReload = true }: ErrorProps) => {
	const { t } = useTranslation('common');

	const handleReload = useCallback(() => {
		location.reload();
	}, []);

	return (
		<Styled.Wrapper tag={tag} gap={16} direction="column">
			<Text align="center" label={message ?? t('page.error.description')} />
			{hasReload && <Button onClick={handleReload} label={t('page.error.button')} size="s" />}
		</Styled.Wrapper>
	);
};

export default memo(ErrorComponent);

import styled from 'styled-components';

export const ContentWrapper = styled.div`
	display: flex;
	width: ${({
		theme: {
			layouts: { sidebar },
		},
	}) => `calc(100% - ${sidebar.width})`};
	flex-direction: column;
	flex: 1;
	height: 100vh;

	@media ${({ theme: { media } }) => media.tablet} {
		padding-left: ${({
			theme: {
				layouts: { sidebar },
			},
		}) => sidebar.collapsed.width};
	}
`;

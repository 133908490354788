import { memo } from 'react';

import { useToasts } from '../../model/selectors/select-toasts';
import { ToastCard } from '../toast-card';
import * as Styled from './toast-container.styles';

const ToastContainerComponent = () => {
	const toasts = useToasts();

	if (!toasts.length) {
		return null;
	}

	return (
		<Styled.Wrapper direction="column" gap={16}>
			{toasts.map((toast, index) => (
				<ToastCard key={toast.id} toast={toast} exceedTime={index > 4} />
			))}
		</Styled.Wrapper>
	);
};

export default memo(ToastContainerComponent);

import { useMemo } from 'react';

import { useIsPlant } from '@/entities/user';
import { AppRoutes } from '@/shared/constants/app-routes.constant';

import { MultiRoutes } from '../types/navigation-item.interface';

export const useRoutes = (): MultiRoutes => {
	const isPlant = useIsPlant();

	return useMemo(
		() => ({
			multiView: isPlant ? AppRoutes.MultiPlantView : AppRoutes.MultiYachtView,
			singleView: isPlant ? AppRoutes.PlantView : AppRoutes.YachtView,
			management: isPlant ? AppRoutes.PlantsManagement : AppRoutes.YachtsFleet,
			editManagement: isPlant ? AppRoutes.EditPlant : AppRoutes.EditYacht,
		}),
		[isPlant],
	);
};

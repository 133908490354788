import styled, { css } from 'styled-components';

import { Box } from '@/shared/ui/box';

import { StyledRoundWrapper } from './icon.types';

export const RoundWrapper = styled(Box)<StyledRoundWrapper>`
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: ${({ theme: { icon }, roundWrapper }) => icon.roundWrapper[roundWrapper].backgroundColor};

	& svg,
	path {
		fill: ${({ theme: { icon }, roundWrapper }) => icon.roundWrapper[roundWrapper].fill};
	}

	${({ size }) =>
		size &&
		css`
			width: ${size};
			height: ${size};
		`}
`;

import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from '@/shared/api/reauth.api';
import { AppStoreTag } from '@/shared/constants/app-store-tag.constant';

export const boatServiceApi = createApi({
	reducerPath: 'boat service',
	tagTypes: [AppStoreTag.Boat, AppStoreTag.BoatDetail, AppStoreTag.Historical],
	baseQuery: baseQueryWithReauth(BOAT_API_URL),
	endpoints: () => ({}),
});

import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useNamespace } from '@/shared/lib/hooks/use-namespace.hook';
import { Box } from '@/shared/ui/box';
import { Text } from '@/shared/ui/text';

import * as Styled from './boat-card.styles';
import { BoatCardProps } from './boat-card.types';

const BoatCardComponent = ({ status, tablePosition, image, lastReceived, mmsi }: BoatCardProps) => {
	const namespace = useNamespace('common');
	const { t } = useTranslation(namespace as ['plant', 'common'] | 'common');

	return (
		<Box direction="column">
			<Styled.Image src={image} alt="Yacht" />
			<Styled.BoxWithBorder>
				<Text label={t('common:realTime.label.position')} weight={600} />
				<Text align="right" label={`${tablePosition[0]} / ${tablePosition[1]}`} weight={500} />
			</Styled.BoxWithBorder>
			<Styled.BoxWithBorder>
				<Text label={t('common:realTime.label.status')} weight={600} />
				<Text label={status} weight={500} />
			</Styled.BoxWithBorder>
			{mmsi && (
				<Styled.BoxWithBorder>
					<Text label="MMSI" weight={600} />
					<Text label={mmsi} weight={500} />
				</Styled.BoxWithBorder>
			)}
			<Styled.BoxWithBorder>
				<Text label={t('realTime.label.lastReceivedData')} weight={600} />
				<Text align="right" label={lastReceived} weight={500} />
			</Styled.BoxWithBorder>
		</Box>
	);
};

export default memo(BoatCardComponent);

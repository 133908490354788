import { createSelector } from '@/shared/lib/helpers/create-store-logic';

export const [useHistoricalDataRange] = createSelector(({ historicalData }) => ({
	from: historicalData.from,
	to: historicalData.to,
	isPeriodSelected: historicalData.isPeriodSelected,
}));

export const [useHistoricalDataVariables] = createSelector(({ historicalData }) => ({
	dataVariables: historicalData.dataVariables,
	isAllDataVariables: historicalData.isAllDataVariables,
	alarmsVariables: historicalData.alarmsVariables,
	isAllAlarmsVariables: historicalData.isAllAlarmsVariables,
	statusesVariables: historicalData.statusesVariables,
	isAllStatusesVariables: historicalData.isAllStatusesVariables,
}));

export const [useHistoricalDataDownloadVariables] = createSelector(({ historicalData }) => {
	const variables = [
		...historicalData.dataVariables,
		...historicalData.alarmsVariables,
		...historicalData.statusesVariables,
	];

	return {
		variables,
		from: historicalData.from,
		to: historicalData.to,
		isDisabled: variables.length <= 0,
	};
});

export const [useFileFormat] = createSelector(({ historicalData }) => historicalData.fileFormat);

import { Helmet } from 'react-helmet-async';
import { useTheme } from 'styled-components';

const ThemeHelmet = () => {
	const { colors } = useTheme();

	return (
		<Helmet>
			<meta content={colors.metaColor} name="theme-color" />
		</Helmet>
	);
};

export { ThemeHelmet };

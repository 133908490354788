import { PayloadAction } from '@reduxjs/toolkit';

import { FileFormat } from '@/shared/constants/file-format.constant';
import { createSlice } from '@/shared/lib/helpers/create-store-logic';

import { HistoricalDataSchema, LastDownloadedFile } from '../types/historical-data.schema';

export const initialState: HistoricalDataSchema = {
	isPeriodSelected: true,
	dataVariables: [],
	alarmsVariables: [],
	statusesVariables: [],
	isAllDataVariables: false,
	isAllAlarmsVariables: false,
	isAllStatusesVariables: false,
	fileFormat: FileFormat.Csv,
};

export const historicalDataSlice = createSlice({
	name: 'historical-data',
	initialState,
	reducers: {
		setTo: (state, action: PayloadAction<Date | undefined>) => {
			state.to = action.payload;
		},
		setFrom: (state, action: PayloadAction<Date | undefined>) => {
			state.from = action.payload;
		},
		setIsPeriodSelected: (state, action: PayloadAction<boolean>) => {
			state.isPeriodSelected = action.payload;
		},
		setDataVariables: (state, action: PayloadAction<string[]>) => {
			state.dataVariables = action.payload;
		},
		setAlarmsVariables: (state, action: PayloadAction<string[]>) => {
			state.alarmsVariables = action.payload;
		},
		setStatusesVariables: (state, action: PayloadAction<string[]>) => {
			state.statusesVariables = action.payload;
		},
		setIsAllDataVariables: (state, action: PayloadAction<boolean>) => {
			state.isAllDataVariables = action.payload;
		},
		setIsAllAlarmsVariables: (state, action: PayloadAction<boolean>) => {
			state.isAllAlarmsVariables = action.payload;
		},
		setIsAllStatusesVariables: (state, action: PayloadAction<boolean>) => {
			state.isAllStatusesVariables = action.payload;
		},
		setLastDownloadedFile: (state, action: PayloadAction<LastDownloadedFile>) => {
			state.lastDownloadedFile = action.payload;
		},
		setFormat: (state, action: PayloadAction<FileFormat>) => {
			state.fileFormat = action.payload;
		},
		resetFormat: (state) => {
			state.fileFormat = FileFormat.Csv;
		},
	},
});

export const {
	actions: historicalDataActions,
	reducer: historicalDataReducer,
	useActions: useHistoricalDataActions,
} = historicalDataSlice;

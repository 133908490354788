export enum UserRole {
	Admin = 'ADMIN',
	MultiBoat = 'MULTI_BOAT',
	SingleBoat = 'SINGLE_BOAT',
}

export enum TenantRole {
	Plant = 'PLANT',
	Boat = 'BOAT',
}

import styled, { keyframes } from 'styled-components';

import { SpinnerProps } from './spinner.types';

const animation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div<Required<Omit<SpinnerProps, 'className'>>>`
	display: inline-block;
	width: ${({ size, theme: { spinner } }) => spinner.size[size]};
	height: ${({ size, theme: { spinner } }) => spinner.size[size]};
	&:after {
		content: ' ';
		display: block;
		width: ${({ size, theme: { spinner } }) => `calc(${spinner.size[size]} - 16px)`};
		height: ${({ size, theme: { spinner } }) => `calc(${spinner.size[size]} - 16px)`};
		margin: ${({ margin }) => margin};
		border-radius: 50%;
		border: ${({ size, theme: { spinner } }) => spinner.border[size]} solid
			${({ color, theme: { spinner } }) => spinner.color[color]};
		border-color: ${({ color, theme: { spinner } }) => spinner.color[color]} transparent
			${({ color, theme: { spinner } }) => spinner.color[color]} transparent;
		animation: ${animation} 1.2s linear infinite;
	}
`;

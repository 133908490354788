import { createContext, SetStateAction } from 'react';

export interface DeltaDataContextProps {
	deltaData: boolean;
	setDeltaData: React.Dispatch<SetStateAction<boolean>>;
}

export const DeltaDataContext = createContext<DeltaDataContextProps>({
	deltaData: true,
	setDeltaData: () => {},
});

import styled from 'styled-components';

import { flexGap } from '@/shared/lib/helpers/flex-gag-polyfill.helper';
import { BoxProps } from '@/shared/ui/box/box.types';

export const Box = styled.div<BoxProps>`
	position: ${({ position }) => position};
	display: ${({ display }) => display};
	flex-direction: ${({ direction }) => direction};
	justify-content: ${({ justify }) => justify};
	align-items: ${({ align }) => align};
	flex: ${({ flex }) => flex};
	flex-wrap: ${({ flexWrap }) => flexWrap};
	width: ${({ width }) => width};
	height: ${({ height }) => height};
	max-width: ${({ maxWidth, theme: { input } }) => maxWidth && input.width[maxWidth]};
	min-width: ${({ minWidth, theme: { input } }) => minWidth && input.minWidth[minWidth]};
	margin-bottom: ${({ marginBottom }) => marginBottom};
	margin-top: ${({ marginTop }) => marginTop};
	margin-left: ${({ marginLeft }) => marginLeft};
	margin-right: ${({ marginRight }) => marginRight};
	padding: ${({ padding }) => padding};
	overflow: ${({ overflow }) => overflow};
	border: ${({ border }) => border && `1px solid ${border}`};
	border-top: ${({ borderTop }) => borderTop && `1px solid ${borderTop}`};
	border-bottom: ${({ borderBottom }) => borderBottom && `1px solid ${borderBottom}`};
	border-left: ${({ borderLeft }) => borderLeft && `1px solid ${borderLeft}`};
	border-right: ${({ borderRight }) => borderRight && `1px solid ${borderRight}`};
	font-size: ${({ fontSize }) => fontSize};
	background: ${({ background }) => background};
	border-radius: ${({ radius }) => radius};
	color: ${({ color }) => color};
	height: ${({ height }) => height};
	cursor: ${({ cursor }) => cursor};
	max-height: ${({ maxHeight }) => maxHeight};
	min-height: ${({ minHeight }) => minHeight};
	${({ gap, direction, flexWrap }) => flexGap(`${gap}px`, { direction, wrap: flexWrap })};

	${({ withoutScroll }) =>
		withoutScroll &&
		`&::-webkit-scrollbar {
		display: none;
	}`}
`;

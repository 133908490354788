import { memo, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useIsPlant } from '@/entities/user/model/selectors/select-user';
import { AppRoutes } from '@/shared/constants/app-routes.constant';

const PlantElementComponent = ({ children }: PropsWithChildren) => {
	const isPlant = useIsPlant();

	if (!isPlant) {
		return <>{children}</>;
	}

	return <Navigate to={AppRoutes.Home} />;
};

export default memo(PlantElementComponent);

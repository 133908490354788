import { PropsWithChildren, useState } from 'react';

import { DeltaDataContext } from '@/shared/lib/contexts/delta-data.context';

const DeltaDataProviderComponent = ({ children }: PropsWithChildren) => {
	const [deltaData, setDeltaData] = useState<boolean>(false);

	return (
		<DeltaDataContext.Provider
			value={{
				deltaData,
				setDeltaData,
			}}
		>
			{children}
		</DeltaDataContext.Provider>
	);
};

export default DeltaDataProviderComponent;

import { AnyAction, combineReducers, Reducer, ReducersMapObject } from '@reduxjs/toolkit';

import { MountedReducers, ReducerManager, StoreSchema, StoreSchemaKey } from './store.schema';

export function createReducerManager(initialReducers: ReducersMapObject<StoreSchema>): ReducerManager {
	const reducers = { ...initialReducers };

	let combinedReducer = combineReducers(reducers);

	let keysToRemove: Array<StoreSchemaKey> = [];
	const mountedReducers: MountedReducers = {};

	return {
		getReducerMap: () => reducers,
		getMountedReducers: () => mountedReducers,
		reduce: (state: StoreSchema, action: AnyAction) => {
			if (keysToRemove.length > 0) {
				state = { ...state };
				keysToRemove.forEach((key) => {
					delete state[key];
				});
				keysToRemove = [];
			}
			return combinedReducer(state, action);
		},
		add: (key: StoreSchemaKey, reducer: Reducer) => {
			if (!key || reducers[key]) {
				return;
			}
			reducers[key] = reducer;
			mountedReducers[key] = true;

			combinedReducer = combineReducers(reducers);
		},
		remove: (key: StoreSchemaKey) => {
			if (!key || !reducers[key]) {
				return;
			}
			delete reducers[key];
			keysToRemove.push(key);
			mountedReducers[key] = false;

			combinedReducer = combineReducers(reducers);
		},
	};
}

import { Popover } from '@headlessui/react';
import styled from 'styled-components';

import { ButtonWrapperStyledProps } from '@/features/grafana-range-picker/ui/grafana-range-picker.types';
import { flexGap } from '@/shared/lib/helpers/flex-gag-polyfill.helper';
import { Text } from '@/shared/ui/text';

export const Wrapper = styled(Popover)`
	position: relative;
`;

export const LabelWrapper = styled(Popover.Button)<ButtonWrapperStyledProps>`
	cursor: pointer;
	text-align: left;
	outline: none;
	background: ${({ theme: { input } }) => input.primary.background};
	border: ${({ theme: { input }, open }) => `1px solid ${open ? input.primary.focus : input.primary.border}`};
	padding: 8px 48px 8px 16px;
	color: ${({ theme: { input } }) => input.primary.fontColor};
	margin: ${({ margin }) => margin};
	background-image: url(${({ disabled, theme: { select } }) => (disabled ? '' : select.arrow.open)});
	background-repeat: no-repeat;
	background-position: calc(100% - 16px) center;
	background-size: 12px;

	&[aria-expanded='true'] {
		background-image: url(${({ theme: { select } }) => select.arrow.close});
	}
`;

export const Content = styled(Popover.Panel)`
	position: absolute;
	right: 0;
	display: flex;
	width: 546px;
	background-color: ${({ theme: { menu } }) => menu.background};
	z-index: ${({ theme: { zIndex } }) => zIndex.menu};
	border: ${({ theme: { menu } }) => menu.border};
	padding: 12px;
	${flexGap('36px')};

	& label {
		flex: initial;
	}

	@media ${({ theme: { media } }) => media.mobile} {
		flex-direction: column;
		${flexGap('16px', { direction: 'column' })};
		width: 250px;
	}
`;

export const TimeText = styled(Text)`
	cursor: pointer;
	padding: 8px 16px;
	padding-left: 0px;

	&:hover {
		background: ${({ theme: { menu } }) => menu.hover};
	}
`;

import { ComponentType, FC, Suspense } from 'react';

import { ErrorBoundary } from '@/app/providers/error-boundary';
import { Spinner } from '@/shared/ui/spinner';

type FallbackType = FC | null;

/**
 * HOC for lazy loading of a component
 * @param {ComponentType<T>} [Component] - Wrapped component
 * @param {FallbackType} [Fallback] - The loader that will be displayed
 * @returns FC<T>
 * @example
 * import { lazy } from 'react';
 *
 * import { withSuspense } from '@/shared/lib/hocs/with-suspense.hoc';
 *
 * const Component = withSuspense(lazy(() => import('./component')));
 */

export const withSuspense =
	<T extends {}>(Component: ComponentType<T>, Fallback: FallbackType = Spinner): FC<T> =>
	(props: T) =>
		(
			<ErrorBoundary>
				<Suspense fallback={Fallback ? <Fallback /> : null}>
					<Component {...props} />
				</Suspense>
			</ErrorBoundary>
		);

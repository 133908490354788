import { PayloadAction } from '@reduxjs/toolkit';

import { WebSocketClient } from '@/shared/lib/classes/web-socket-client.class';
import { createSlice } from '@/shared/lib/helpers/create-store-logic';

import { HistoricalTableSchema } from '../types/historical-data.schema';

const initialState: HistoricalTableSchema = {};

export const historicalTableSlice = createSlice({
	name: 'historical table',
	initialState,
	reducers: {
		addSocket: (state, action: PayloadAction<WebSocketClient>) => {
			state.socket = action.payload;
		},
		closeSocket: (state) => {
			if (state.socket) {
				state.socket.close();
				state.socket = undefined;
			}
		},
	},
});

export const {
	actions: historicalTableActions,
	reducer: historicalTableReducer,
	useActions: useHistoricalTableActions,
} = historicalTableSlice;

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { createStore } from '../../model/create-store';
import { StoreProviderProps } from './store-provider.types';
const StoreProviderComponent = ({
	children,
	preloadedState,
	lazyReducers,
	hasPersistor = true,
}: StoreProviderProps) => {
	const { persistor, ...store } = createStore(preloadedState, lazyReducers, hasPersistor);

	if (hasPersistor) {
		return (
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					{children}
				</PersistGate>
			</Provider>
		);
	}

	return <Provider store={store}>{children}</Provider>;
};

export default StoreProviderComponent;

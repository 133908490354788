import { PayloadAction } from '@reduxjs/toolkit';

import { WebSocketClient } from '@/shared/lib/classes/web-socket-client.class';
import { createSlice } from '@/shared/lib/helpers/create-store-logic';

import { VirtualCockpitSchema } from '../types/virtual-cockpit.interface';

const initialState: VirtualCockpitSchema = {};

export const virtualCockpitSlice = createSlice({
	name: 'virtual cockpit',
	initialState,
	reducers: {
		addSocket: (state, action: PayloadAction<WebSocketClient>) => {
			state.socket = action.payload;
		},
		closeSocket: (state) => {
			if (state.socket) {
				state.socket.close();
				state.socket = undefined;
			}
		},
	},
});

export const {
	actions: virtualCockpitActions,
	reducer: virtualCockpitReducer,
	useActions: useVirtualCockpitActions,
} = virtualCockpitSlice;

import { useMemo } from 'react';

import { useIsPlant } from '@/entities/user';

export interface MultiI18nKeys {
	sidebarSingleView: 'sidebar.navigation.data.plant' | 'sidebar.navigation.data.yacht';
	sidebarMultiView: 'sidebar.navigation.data.multi.yacht' | 'sidebar.navigation.data.multi.plant';
}

export const useMultiI18nKeys = (): MultiI18nKeys => {
	const isPlant = useIsPlant();

	return useMemo(
		() => ({
			sidebarSingleView: isPlant ? 'sidebar.navigation.data.plant' : 'sidebar.navigation.data.yacht',
			sidebarMultiView: isPlant ? 'sidebar.navigation.data.multi.plant' : 'sidebar.navigation.data.multi.yacht',
		}),
		[isPlant],
	);
};

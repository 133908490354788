import { memo } from 'react';

import * as Styled from './image.styles';
import { ImageProps } from './image.types';

const ImageComponent = ({ src, alt = '', paragraph = '', className, height, width }: ImageProps) => {
	return <Styled.Image src={src} alt={alt} paragraph={paragraph} className={className} width={width} height={height} />;
};

export default memo(ImageComponent);

import { forwardRef, memo } from 'react';

import * as Styled from './caption.styles';
import { CaptionProps } from './caption.types';

const CaptionComponent = forwardRef<HTMLSpanElement, CaptionProps>(
	({ weight = 400, opacity = 1, label, color = 'primary', whiteSpace = 'normal', ...props }, ref) => {
		return (
			<Styled.Caption weight={weight} opacity={opacity} color={color} whiteSpace={whiteSpace} {...props} ref={ref}>
				{label}
			</Styled.Caption>
		);
	},
);

export default memo(CaptionComponent);

import { OFFLINE_LABEL } from '@/shared/constants/offline-label.constant';
import { convertTimezone } from '@/shared/lib/helpers/convert-timezone.helper';
import { dateFormat } from '@/shared/lib/helpers/date-format.helper';
import { roundOffPosition } from '@/shared/lib/helpers/round-off-position.helper';
import { transformSeconds } from '@/shared/lib/helpers/transform-seconds.helper';

import { MacroSystem, MacroSystemsResponse } from '../types/systems.interface';

const getVariableValue = (value: number | string | null, delta?: string, tags?: string[]) => {
	if (value || value === 0) {
		if (delta === OFFLINE_LABEL) {
			return '---';
		}

		if (typeof value === 'string') {
			return value;
		}

		return roundOffPosition(String(value), tags);
	}

	return '';
};

export const transformRealTimeDataResponse = (response: MacroSystemsResponse, timezone: string): MacroSystem[] =>
	response.macro_systems.map((system) => ({
		name: system.name,
		systems: system.systems.map((macrosystem) => ({
			name: macrosystem.name,
			identifier: macrosystem.identifier,
			variables: macrosystem.variables.map((variable) => ({
				unifiedName: variable.unified_name,
				unitOfMeasure: variable.unit_of_measure,
				value: getVariableValue(variable.value, transformSeconds(variable.time_delta), variable.tags),
				timeDelta: transformSeconds(variable.time_delta),
			})),
		})),
		tableData: system.table_data.map((data) => ({
			identifiers: data.identifiers,
			systemName: data.system_name,
			variables: data.variables.map((variable) => ({
				unifiedName: variable.unified_name,
				unitOfMeasure: variable.unit_of_measure,
				values: variable.values.map(({ value, time_delta }) => ({
					value: getVariableValue(value, transformSeconds(time_delta)),
					timeDelta: transformSeconds(time_delta),
				})),
			})),
		})),
		updatedAt: system.updated_at ? dateFormat(convertTimezone(system.updated_at, timezone)) : null,
	}));

import '@/shared/configs/i18n.config';

import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { App } from '@/app';
import { ErrorBoundary } from '@/app/providers/error-boundary';
import { StoreProvider } from '@/app/providers/store';

const rootElement = document.getElementById('root');

if (rootElement) {
	const root = createRoot(rootElement);

	root.render(
		<BrowserRouter>
			<StoreProvider>
				<HelmetProvider>
					<ErrorBoundary>
						<App />
					</ErrorBoundary>
				</HelmetProvider>
			</StoreProvider>
		</BrowserRouter>,
	);
} else {
	throw new Error('Root container missing in index.html');
}

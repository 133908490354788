import { ThemeProvider } from 'styled-components';

import { ThemeHelmet } from '@/app/theme/theme-helmet';
import { BoatGate } from '@/entities/boat';
import { ModalContainer } from '@/entities/modal';
import { ToastContainer } from '@/entities/toast';
import { UserGate } from '@/entities/user';
import { useCurrentTheme } from '@/features/change-theme';
import { withChangedDom } from '@/shared/lib/hocs/with-changed-dom.hoc';

import { DeltaDataProvider } from './providers/delta-data';
import { HtmlContent } from './providers/html-content';
import { AuthRouter } from './providers/router';
import { ScrollToTop } from './providers/router/';
import { TimezoneProvider } from './providers/timezone';
import { GlobalStyle } from './styles/global-styles';

const App = withChangedDom(() => {
	const theme = useCurrentTheme();

	return (
		<ThemeProvider theme={theme}>
			<GlobalStyle />
			<ToastContainer />
			<ModalContainer />
			<ScrollToTop />
			<ThemeHelmet />
			<UserGate>
				{(isAuth) =>
					isAuth ? (
						<BoatGate>
							<TimezoneProvider>
								<DeltaDataProvider>
									<HtmlContent />
								</DeltaDataProvider>
							</TimezoneProvider>
						</BoatGate>
					) : (
						<AuthRouter />
					)
				}
			</UserGate>
		</ThemeProvider>
	);
});

export { App };

import { useEffect } from 'react';

import { PageLoader } from '@/widgets/page';

import { RedirectProps } from './redirect.types';

const RedirectComponent = ({ externalLink }: RedirectProps) => {
	useEffect(() => {
		window.location.href = externalLink;
	}, [externalLink]);

	return <PageLoader />;
};

export default RedirectComponent;

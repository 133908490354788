import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from '@/shared/api/reauth.api';

import { AppStoreTag } from '../constants/app-store-tag.constant';

export const userServiceApi = createApi({
	reducerPath: 'user service',
	tagTypes: [AppStoreTag.User, AppStoreTag.Users, AppStoreTag.UserDetail, AppStoreTag.AvailableUsers],
	baseQuery: baseQueryWithReauth(USER_API_URL),
	endpoints: () => ({}),
});

import { Disclosure } from '@headlessui/react';
import { useRef } from 'react';

import { useOutsideClick } from '@/shared/lib/hooks/use-outside-click.hook';

import * as Styled from './accordion.styles';
import { AccordionProps } from './accordion.types';

const AccordionComponent = ({ renderSwitch, outsideClick = true, children }: AccordionProps) => {
	const panelRef = useRef<HTMLDivElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);

	useOutsideClick(panelRef, (event) => {
		if (
			buttonRef.current &&
			buttonRef.current.ariaExpanded === 'true' &&
			!buttonRef.current.contains(event.target as Node) &&
			!outsideClick
		) {
			buttonRef.current.click();
		}
	});

	return (
		<Disclosure>
			{({ open, close }) => (
				<>
					<Styled.Button ref={buttonRef}>{({ open }) => renderSwitch({ open, close })}</Styled.Button>
					<Styled.Panel open={open} static ref={panelRef}>
						{children({ open, close })}
					</Styled.Panel>
				</>
			)}
		</Disclosure>
	);
};

export default AccordionComponent;

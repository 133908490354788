import { memo } from 'react';

import { cutText } from '@/shared/lib/helpers/cut-text.helper';
import { Tooltip } from '@/shared/ui/tooltip';

import { LongTextProps } from './long-text.types';

const LongTextComponent = ({
	label,
	length = 20,
	direction = 'bottom',
	position = 'absolute',
	flexDirection,
	maxWidth,
	Component = null,
}: LongTextProps) => {
	if (label?.length > length) {
		return (
			<Tooltip
				tooltip={label}
				direction={direction}
				position={position}
				flexDirection={flexDirection}
				maxWidth={maxWidth}
			>
				{!Component && cutText(label, length)}
				{Component}
			</Tooltip>
		);
	}

	return (
		<>
			{!Component && label}
			{Component}
		</>
	);
};

export default memo(LongTextComponent);

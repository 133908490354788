import { lazy } from 'react';

import { withSuspense } from '@/shared/lib/hocs/with-suspense.hoc';

import { NotificationMenuSpinner } from './ui/spinner';

export const NotificationMenu = withSuspense(
	lazy(() => import('./notification-menu.component')),
	NotificationMenuSpinner,
);

import { boatActions } from '@/entities/boat';
import { transformUserResponse } from '@/entities/user/model/helpers/transform-user-response.helper';
import { boatServiceApi } from '@/shared/api/boat-service.api';
import { dashboardServiceApi } from '@/shared/api/dashboard-service.api';
import { userServiceApi } from '@/shared/api/user-service.api';
import i18n from '@/shared/configs/i18n.config';
import { AppLanguage } from '@/shared/constants/app-language.constant';
import { AppStorage } from '@/shared/constants/app-storage.constant';

import { userActions } from '../model/slice/user.slice';
import { User } from '../model/types/user.interface';
import {
	UserInitResponse,
	UserRefreshTokenParsedResponse,
	UserRefreshTokenResponse,
	UserRefreshTokenVariables,
} from '../model/types/user-api.interface';

const userApi = userServiceApi.injectEndpoints({
	endpoints: (build) => ({
		userInit: build.query<User, unknown>({
			query: () => ({
				method: 'GET',
				url: 'user/me',
			}),
			transformResponse: (response: UserInitResponse): User => transformUserResponse(response),
			async onQueryStarted(variables, { queryFulfilled, dispatch }) {
				try {
					const { data } = await queryFulfilled;
					localStorage.setItem(AppStorage.User, data.id);
					await i18n.changeLanguage(AppLanguage.English);
					dispatch(userActions.setUser(data));
				} catch {
				} finally {
					dispatch(userActions.setInited(true));
				}
			},
		}),
		refreshToken: build.mutation<UserRefreshTokenParsedResponse, UserRefreshTokenVariables>({
			query: () => ({
				method: 'POST',
				url: 'user/refresh',
			}),
			transformResponse: (response: UserRefreshTokenResponse): UserRefreshTokenParsedResponse => ({
				refreshToken: response.refresh_token,
				accessToken: response.access_token,
			}),
			async onQueryStarted(variables, { queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					localStorage.setItem(AppStorage.Token, data.refreshToken);
					localStorage.setItem(AppStorage.AccessToken, data.accessToken);
				} catch {}
			},
		}),
		logout: build.mutation<string, void>({
			query: () => {
				return {
					method: 'POST',
					url: 'grafana/logout',
					responseHandler: 'text',
				};
			},
			async onQueryStarted(variables, { queryFulfilled, dispatch }) {
				try {
					await queryFulfilled;
				} catch {
				} finally {
					dispatch(userActions.logout());
					dispatch(boatActions.resetBoatById());
					dispatch(userServiceApi.util.resetApiState());
					dispatch(boatServiceApi.util.resetApiState());
					dispatch(dashboardServiceApi.util.resetApiState());
				}
			},
		}),
	}),
});

export const { useUserInitQuery, useLogoutMutation } = userApi;

import styled, { keyframes } from 'styled-components';

import { SkeletonProps } from './skeleton.types';

const skeletonAnimation = keyframes`
  from {
    left: -200px;
  }
  to {
    left: 100%;
  }
`;

export const SkeletonWrapper = styled.div<SkeletonProps>`
	width: ${({ width }) => width};
	height: ${({ height }) => height};
	box-shadow: 0 2px 10px 0 ${({ theme: { skeleton } }) => skeleton.shadow};
	border-radius: ${({ radius }) => radius};
	position: relative;
	overflow: hidden;

	&:before {
		content: '';
		display: block;
		position: absolute;
		left: -200px;
		top: 0;
		height: 100%;
		width: 200px;
		background: ${({ theme: { skeleton } }) => skeleton.gradient};
		animation: ${skeletonAnimation} 1s ease-in-out infinite;
	}
`;

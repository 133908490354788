import { Dialog as HeadlessDialog } from '@headlessui/react';
import styled from 'styled-components';

export const Dialog = styled(HeadlessDialog)`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: ${({ theme: { zIndex } }) => zIndex.modal};
	padding: 0 16px;
`;

export const Panel = styled(HeadlessDialog.Panel)`
	background-color: #fff;
`;

import { AppStorage } from '@/shared/constants/app-storage.constant';
import { AppTheme } from '@/shared/constants/app-theme.constant';
import { createSelector } from '@/shared/lib/helpers/create-store-logic';

import { UserRole } from '../constants/user-role.constant';
import { userInitialState } from '../slice/user.slice';

export const [useUser] = createSelector((state) => state.user.data ?? userInitialState);

export const [useUserTheme] = createSelector((state) => state.user.data?.theme ?? AppTheme.Light);
export const [useIsAuth] = createSelector((state) => Boolean(state.user.data));
export const [useIsInited] = createSelector((state) => state.user.inited);
export const [useUserId] = createSelector((state) => state.user.data?.id || '');

export const [useUserRole, selectUserRole] = createSelector((state, roles: UserRole[]) =>
	roles.some((role) => role === state.user.data?.role),
);

export const [useCurrentUserNavigation] = createSelector((state) => ({
	role: state.user.data?.role ?? UserRole.Admin,
	isPlant: state.user.data?.isPlant ?? false,
}));

export const [useAccessToken] = createSelector(
	(state) => state.user.accessToken ?? localStorage.getItem(AppStorage.AccessToken),
);

export const [useTelegram] = createSelector((state) => ({
	username: state.user.data?.telegram ?? '',
}));

export const [useAllowEditDashboard] = createSelector((state) => state.user.data?.allowDashboardEdit);

export const [useIsPlant] = createSelector((state) => state.user.data?.isPlant ?? false);

import { PayloadAction } from '@reduxjs/toolkit';

import { WebSocketClient } from '@/shared/lib/classes/web-socket-client.class';
import { createSlice } from '@/shared/lib/helpers/create-store-logic';

import { NotificationsSchema } from '../types/notification.interface';

const initialState: NotificationsSchema = {};

export const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		addSocket: (state, action: PayloadAction<WebSocketClient>) => {
			state.socket = action.payload;
		},
		closeSocket: (state) => {
			if (state.socket) {
				state.socket.close();
				state.socket = undefined;
			}
		},
	},
});

export const {
	actions: notificationsActions,
	reducer: notificationsReducer,
	useActions: useNotificationsActions,
} = notificationsSlice;

import { selectBoat } from '@/entities/boat';
import { createSelector } from '@/shared/lib/helpers/create-store-logic';

export const [useSelectedBoat] = createSelector((state) => selectBoat.selectById(state, state.boat.selectedId || ''));

export const [useBoatById] = createSelector(
	(state, id: string) => selectBoat.selectById(state, id) ?? { name: '', id: '', mmsi: '', uuid: '', imageUrl: '' },
);

export const [useIsSelectBoat] = createSelector((state) => Boolean(state.boat.selectedId));

export const [useBoatLength] = createSelector((state) => state.boat.ids?.length);

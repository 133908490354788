import { dashboardServiceApi } from '@/shared/api/dashboard-service.api';
import { AppStoreTag } from '@/shared/constants/app-store-tag.constant';
import { WebSocketClient } from '@/shared/lib/classes/web-socket-client.class';

import { transformRealTimeDataResponse } from '../model/helpers/transform-real-time-data-response.helper';
import { virtualCockpitActions } from '../model/slice/virtual-cockpit.slice';
import { MacroSystem, MacroSystemsResponse, VirtualCockpitApiVariables } from '../model/types/systems.interface';

const virtualCockpitApi = dashboardServiceApi.injectEndpoints({
	endpoints: (build) => ({
		getRealTimeData: build.query<MacroSystem[], VirtualCockpitApiVariables>({
			providesTags: [AppStoreTag.RealTimeData],
			query: ({ uuid }) => ({
				method: 'GET',
				url: `virtual-cockpit/${uuid}`,
			}),
			transformResponse: (response: MacroSystemsResponse, _, { timezone }) =>
				transformRealTimeDataResponse(response, timezone),
			async onQueryStarted({ uuid, token, timezone }, { queryFulfilled, dispatch, updateCachedData }) {
				try {
					await queryFulfilled;
					const socket = new WebSocketClient(`cockpit/${uuid}?token=${token}`);

					dispatch(virtualCockpitActions.addSocket(socket));

					try {
						socket?.addMessageListener<MacroSystemsResponse>((data) => {
							updateCachedData((draft) => {
								draft.splice(0, draft.length, ...transformRealTimeDataResponse(data, timezone));
							});
						});
					} catch {
						socket.addErrorListener();
					}
				} catch {}
			},
		}),
	}),
});

export const { useGetRealTimeDataQuery } = virtualCockpitApi;

import { forwardRef, memo } from 'react';

import * as Styled from './box.styles';
import { BoxProps } from './box.types';

const BoxComponent = forwardRef<HTMLDivElement, BoxProps>(
	({ children, position = 'static', display = 'flex', direction = 'row', tag = 'div', ...props }, ref) => {
		return (
			<Styled.Box ref={ref} as={tag} position={position} display={display} direction={direction} {...props}>
				{children}
			</Styled.Box>
		);
	},
);

export default memo(BoxComponent);

import { dashboardServiceApi } from '@/shared/api/dashboard-service.api';
import { AppStoreTag } from '@/shared/constants/app-store-tag.constant';

import {
	BoatGetLocationsResponse,
	BoatLocation,
	BoatLocationYachtOverview,
	BoatLocationYachtOverviewResponse,
	BoatMapApiVariables,
} from '../model/types/boat-map-api.interface';
import { parseBoatLocation } from '../model/utils/parse-boat-location.util';

const boatMapApi = dashboardServiceApi.injectEndpoints({
	endpoints: (build) => ({
		getLocations: build.query<BoatLocation[], string>({
			providesTags: [AppStoreTag.Map],
			query: () => ({
				method: 'GET',
				url: 'map/last-location',
			}),
			transformResponse: (response: BoatGetLocationsResponse[], _, timezone): BoatLocation[] =>
				response.map((data) => parseBoatLocation(data, timezone)),
		}),
		getLocation: build.query<BoatLocation, BoatMapApiVariables>({
			query: ({ uuid }) => ({
				method: 'GET',
				url: `map/last-location/${uuid}`,
			}),
			transformResponse: (response: BoatGetLocationsResponse, _, { timezone }): BoatLocation =>
				parseBoatLocation(response, timezone),
		}),
		getLocationYachtOverview: build.query<BoatLocationYachtOverviewResponse, BoatLocationYachtOverview>({
			query: ({ uuid }) => ({
				method: 'GET',
				url: `map/last-location/${uuid}`,
			}),
			transformResponse: (response: BoatGetLocationsResponse, _, { timezone, id }) => {
				const boat = parseBoatLocation({ ...response, id: id ? Number(id) : response.id }, timezone);

				return { boat, boatLocations: [boat] };
			},
		}),
		getLocationManual: build.mutation<BoatLocation, BoatMapApiVariables>({
			query: ({ uuid }) => ({
				method: 'GET',
				url: `map/last-location/${uuid}`,
			}),
			transformResponse: (response: BoatGetLocationsResponse, _, { timezone }): BoatLocation => ({
				...parseBoatLocation(response, timezone),
			}),
		}),
	}),
});

export const {
	useGetLocationQuery,
	useGetLocationManualMutation,
	useGetLocationsQuery,
	useGetLocationYachtOverviewQuery,
} = boatMapApi;

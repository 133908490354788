import styled from 'styled-components';

import { Box } from '@/shared/ui/box';

export const Wrapper = styled(Box)`
	position: fixed;
	top: 5%;
	z-index: ${({ theme: { zIndex } }) => zIndex.toast};
	left: 50%;
	transform: translateX(-50%);
`;

import { memo } from 'react';

import { Box } from '@/shared/ui/box';
import { Skeleton } from '@/shared/ui/skeleton';

const NotificationMenuSpinnerComponent = () => {
	return (
		<Box marginRight="12px">
			<Skeleton width="18px" height="22px" radius="6px" />
		</Box>
	);
};

export default memo(NotificationMenuSpinnerComponent);

import { memo } from 'react';

import * as Styled from './spinner.styles';
import { SpinnerProps } from './spinner.types';

const SpinnerComponent = ({ size = 'l', color = 'primary', margin = '8px', ...props }: SpinnerProps) => {
	return <Styled.Spinner size={size} color={color} margin={margin} {...props} />;
};

export default memo(SpinnerComponent);

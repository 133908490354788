type DateFormat = 'full' | 'date' | 'time' | 'fullForTooltip';
type Separator = '-' | ':' | '/';

interface DateFormatOptions {
	format: DateFormat;
	dateSeparator?: Separator;
	timeSeparator?: Separator;
}

export const dateFormat = (
	date: Date,
	options: DateFormatOptions = { dateSeparator: '-', timeSeparator: ':', format: 'full' },
) => {
	const { dateSeparator = '-', format, timeSeparator = ':' } = options;
	const day = date.getDate().toString().padStart(2, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const year = date.getFullYear().toString();
	const hours = date.getHours().toString().padStart(2, '0');
	const minutes = date.getMinutes().toString().padStart(2, '0');
	const seconds = date.getSeconds().toString().padStart(2, '0');

	if (format === 'date') {
		return `${day}${dateSeparator}${month}${dateSeparator}${year}`;
	}

	if (format === 'time') {
		return `${hours}${timeSeparator}${minutes}${timeSeparator}${seconds}`;
	}

	if (format === 'fullForTooltip') {
		return `${day}-${month}-${year} ${hours}:${minutes}`;
	}

	return `${day}${dateSeparator}${month}${dateSeparator}${year} / ${hours}${timeSeparator}${minutes}${timeSeparator}${seconds}`;
};

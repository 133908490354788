import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { AppLanguage } from '@/shared/constants/app-language.constant';
import { generateI18nResources } from '@/shared/lib/helpers/generate-i18n-resources.helper';

const { supportedLngs, resources } = generateI18nResources();

i18n
	.use(Backend)
	.use(initReactI18next)
	.init({
		fallbackLng: AppLanguage.English,
		lng: AppLanguage.English,
		debug: IS_DEV,
		ns: ['common', 'helmet'],
		backend: {
			loadPath: `/locales/{{lng}}/{{ns}}.json?cb=${new Date().getTime()}`,
		},
		supportedLngs,
		resources,
	});

export default i18n;

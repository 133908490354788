import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { BoatConnectionStatus } from '@/shared/constants/boat-connection-status.constant';
import { useNamespace } from '@/shared/lib/hooks/use-namespace.hook';
import { Warning } from '@/shared/ui/warning';

import { BoatOfflineStatus } from './boat-offline-status.types';

const BoatOfflineStatusComponent = ({ status }: BoatOfflineStatus) => {
	const namespace = useNamespace('yachts');
	const { t } = useTranslation(namespace as ['plant', 'yachts'] | 'yachts');

	if (status !== BoatConnectionStatus.Offline) {
		return null;
	}

	return <Warning label={t('offline.warning')} />;
};

export default memo(BoatOfflineStatusComponent);

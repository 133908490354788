import { RefObject, useEffect } from 'react';

/**
 * Hook to control content when clicked from the outside
 * @param {RefObject<T>} [ref] - Ref for the component to which the logic will apply
 * @param {(event:Event) => void} [action] - Action to be performed when clicking
 * @return void
 * @example
 * const ref = useRef();
 *
 * const onSomeAction = ()=>{...};
 *
 * useOutsideClick(ref, onSomeAction);
 *
 * return <Box ref={ref} />;
 */

export const useOutsideClick = <T extends HTMLElement>(ref: RefObject<T>, action: (event: Event) => void) => {
	useEffect(() => {
		const handleClickOutside = (event: Event) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				action(event);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		document.addEventListener('touchstart', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('touchstart', handleClickOutside);
		};
	}, [ref, action]);
};

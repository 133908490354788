import { createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';

import { StoreSchema } from '@/app/providers/store';
import { AppStorage } from '@/shared/constants/app-storage.constant';
import { createSlice } from '@/shared/lib/helpers/create-store-logic';

import { BoatSchema, BoatSearch } from '../types/boat.interface';

const boatsAdapter = createEntityAdapter<BoatSearch>({
	selectId: (boat) => boat.id,
});

export const selectBoat = boatsAdapter.getSelectors<StoreSchema>((state) => {
	if (Object.keys(state.boat).length) {
		return state.boat;
	}

	return boatsAdapter.getInitialState();
});

export const boatSlice = createSlice({
	name: 'boat',
	initialState: boatsAdapter.getInitialState<BoatSchema>({ ids: [], entities: {} }),
	reducers: {
		setBoats: boatsAdapter.setAll,
		removeBoat: boatsAdapter.removeOne,
		addBoat: boatsAdapter.addOne,
		updateBoat: (state, action: PayloadAction<BoatSearch>) => {
			boatsAdapter.updateOne(state, { id: action.payload.id, changes: action.payload });
		},
		setBoatById: (state, action: PayloadAction<string>) => {
			state.selectedId = action.payload;
			localStorage.setItem(AppStorage.Boat, action.payload);
		},
		resetBoatById: (state) => {
			state.selectedId = undefined;
			localStorage.removeItem(AppStorage.Boat);
		},
	},
});

export const { actions: boatActions, reducer: boatReducer, useActions: useBoatActions } = boatSlice;

import { dashboardServiceApi } from '@/shared/api/dashboard-service.api';
import { AppStoreTag } from '@/shared/constants/app-store-tag.constant';
import { WebSocketClient } from '@/shared/lib/classes/web-socket-client.class';

import { historicalTableActions } from '../model/slice/historical-table.slice';
import {
	DownloadedHistoricalData,
	DownloadHistoricalData,
	DownloadHistoricalDataResponse,
	HistoricalDataResponse,
	HistoricalDownloadStatusVariables,
} from '../model/types/historical-data.interface';
import { transformHistoricalDataResponse } from '../model/utils/transform-historical-data-response.util';

const historicalDataApi = dashboardServiceApi.injectEndpoints({
	endpoints: (build) => ({
		getHistoricalDownloadStatus: build.query<DownloadedHistoricalData[], HistoricalDownloadStatusVariables>({
			providesTags: [AppStoreTag.Historical],
			query: ({ uuid }) => ({
				method: 'GET',
				url: `historical/${uuid}`,
			}),
			transformResponse: (response: DownloadHistoricalDataResponse[], _, { timezone }): DownloadedHistoricalData[] =>
				transformHistoricalDataResponse(response, timezone),
			async onQueryStarted({ uuid, token, timezone }, { queryFulfilled, dispatch, updateCachedData }) {
				try {
					await queryFulfilled;
					const socket = new WebSocketClient(`historical/${uuid}?token=${token}`);

					dispatch(historicalTableActions.addSocket(socket));

					try {
						socket.addMessageListener<DownloadHistoricalDataResponse[]>((data) => {
							updateCachedData((draft) => {
								draft.splice(0, draft.length, ...transformHistoricalDataResponse(data, timezone));
							});
						});
					} catch {
						socket.addErrorListener();
					}
				} catch {}
			},
		}),
		downloadHistoricalData: build.mutation<HistoricalDataResponse, DownloadHistoricalData>({
			query: (body) => ({
				method: 'POST',
				url: 'historical/',
				body,
			}),
		}),
	}),
});

export const { useGetHistoricalDownloadStatusQuery, useDownloadHistoricalDataMutation } = historicalDataApi;

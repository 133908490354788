import { memo } from 'react';

import ArrowSvg from '@/shared/assets/icons/ui-kit/accordion-arrow.svg';
import { Accordion } from '@/shared/ui/accordion';
import { Box } from '@/shared/ui/box';
import { Text } from '@/shared/ui/text';

import { NavigationLink } from '../navigation-link';
import * as Styled from './navigation-accordion.styles';
import { NavigationAccordionProps } from './navigation-accordion.types';

const NavigationAccordionComponent = ({ title, items, collapsed, close }: NavigationAccordionProps) => {
	return (
		<Accordion
			renderSwitch={({ open }) => (
				<>
					<Text label={title} weight={600} color="invertedPrimary" />
					<Styled.Arrow Svg={ArrowSvg} open={open} />
				</>
			)}
		>
			{() => (
				<Box direction="column" gap={16} marginTop="16px">
					{items.map((route) => (
						<NavigationLink close={close} key={route.label} collapsed={collapsed} {...route} />
					))}
				</Box>
			)}
		</Accordion>
	);
};

export default memo(NavigationAccordionComponent);

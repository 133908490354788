import { memo } from 'react';

import * as Styled from './warning.styles';
import { WarningProps } from './warning.types';

const WarningComponent = ({ label }: WarningProps) => {
	return <Styled.WarningWrapper>{label}</Styled.WarningWrapper>;
};

export default memo(WarningComponent);

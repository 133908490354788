import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from '@/shared/api/reauth.api';
import { AppStoreTag } from '@/shared/constants/app-store-tag.constant';

export const dashboardServiceApi = createApi({
	reducerPath: 'dashboard service',
	tagTypes: [
		AppStoreTag.Dashboard,
		AppStoreTag.RealTimeData,
		AppStoreTag.RealTimeAlarms,
		AppStoreTag.RealTimeStatus,
		AppStoreTag.Histogram,
		AppStoreTag.Templates,
		AppStoreTag.Historical,
		AppStoreTag.Map,
		AppStoreTag.Notifications,
	],
	baseQuery: baseQueryWithReauth(DASHBOARD_API_URL),
	endpoints: () => ({}),
});

import { memo, useCallback, useEffect } from 'react';
import { useTheme } from 'styled-components';

import CloseIcon from '@/shared/assets/icons/toast/close.svg';
import ErrorIcon from '@/shared/assets/icons/toast/error.svg';
import SuccessIcon from '@/shared/assets/icons/toast/success.svg';
import WarningIcon from '@/shared/assets/icons/toast/warning.svg';
import { Caption } from '@/shared/ui/caption';

import { useToastActions } from '../../model/slice/toast.slice';
import * as Styled from './toast-card.styles';
import { ToastProps } from './toast-card.types';

const ToastIcon = {
	success: SuccessIcon,
	warning: WarningIcon,
	error: ErrorIcon,
};

const AUTO_CLOSE = 5000;

const ToastCardComponent = ({ className, toast: { id, type, message, title, list }, exceedTime }: ToastProps) => {
	const {
		toast: { closeColor },
	} = useTheme();
	const { removeToast } = useToastActions();

	const closeToast = useCallback(() => {
		removeToast(id);
	}, [removeToast, id]);

	useEffect(() => {
		setTimeout(() => {
			closeToast();
		}, AUTO_CLOSE);
	}, [closeToast]);

	useEffect(() => {
		if (exceedTime) {
			closeToast();
		}
	}, [exceedTime, closeToast]);

	return (
		<Styled.Wrapper onClick={closeToast} align="flex-start" type={type} className={className} gap={8}>
			<Styled.ToastIcon Svg={ToastIcon[type]} />
			<Styled.Content direction="column" gap={4}>
				<Caption label={title} weight={600} />
				<Caption label={message} />
				{list && list.length > 0 && <Styled.List label={list.join(', ')} />}
			</Styled.Content>
			<Styled.CloseIcon Svg={CloseIcon} fill={closeColor} />
		</Styled.Wrapper>
	);
};

export default memo(ToastCardComponent);

import styled from 'styled-components';

import { Box } from '@/shared/ui/box';
import { Image as SharedImage } from '@/shared/ui/image';

export const Image = styled(SharedImage)`
	padding: 20px 20px 0;
	width: 100%;
	object-fit: contain;
`;

export const BoxWithBorder = styled(Box)`
	justify-content: space-between;
	padding: 8px 20px;
	border-bottom: 1px solid ${({ theme: { box } }) => box.border};
`;

import styled from 'styled-components';

import { StyledProps } from '@/shared/lib/interfaces/styled-props.interface';
import { Box } from '@/shared/ui/box';
import { Link } from '@/shared/ui/link';

export const Wrapper = styled(Box)``;

export const BreadcrumbLink = styled(Link)<StyledProps>`
	align-items: center;
	gap: 0;
	border-radius: 2px;
	pointer-events: ${({ active }) => active && 'none'};

	& > p {
		padding: 4px 8px;
		background-color: ${({ active, theme: { breadcrumbs } }) => active && breadcrumbs.background};
		color: ${({ active, theme: { breadcrumbs } }) => active && breadcrumbs.color};
		border: ${({ active, theme: { breadcrumbs } }) => active && breadcrumbs.border};
		border-radius: ${({ active, theme: { breadcrumbs } }) => active && breadcrumbs.radius};
		margin-left: ${({ active }) => active && '8px'};
	}
`;

import { memo } from 'react';

import * as Styled from './title.styles';
import { TitleProps } from './title.types';

const TitleComponent = ({
	tag = 'h2',
	label,
	color = 'primary',
	paragraph = '0',
	align = 'left',
	...props
}: TitleProps) => {
	return (
		<Styled.Title tag={tag} as={tag} color={color} paragraph={paragraph} align={align} {...props}>
			{label}
		</Styled.Title>
	);
};

export default memo(TitleComponent);

import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import UpdateSvg from '@/shared/assets/icons/ui-kit/update.svg';
import { Icon } from '@/shared/ui/icon';
import { Text } from '@/shared/ui/text';

import * as Styled from './update-boat.styles';
import { UpdateBoatProps } from './update-boat.types';

const UpdateBoatComponent = ({ onUpdate }: UpdateBoatProps) => {
	const { t, ready } = useTranslation('common');

	if (!ready) {
		return null;
	}

	return (
		<Styled.Wrapper onClick={onUpdate} gap={4}>
			<Text label={t('update.boat.label')} weight={500} color="inherit" />
			<Icon Svg={UpdateSvg} />
		</Styled.Wrapper>
	);
};

export default memo(UpdateBoatComponent);

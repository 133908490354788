import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { AppRoutes } from '@/shared/constants/app-routes.constant';
import { useMatchMedia } from '@/shared/lib/hooks/use-match-media.hook';

const MobileElementComponent = ({ children }: PropsWithChildren) => {
	const isMobile = useMatchMedia('mobile');

	if (isMobile) {
		return <>{children}</>;
	}

	return <Navigate to={AppRoutes.Home} />;
};

export default MobileElementComponent;

import { Title } from '@/shared/ui/title';

import * as Styled from './page-wrapper.styles';
import { PageWrapperProps } from './page-wrapper.types';

const PageWrapperComponent = ({
	children,
	wrapper = false,
	breadcrumbs = false,
	paragraph = '0',
	overflow = 'auto',
	title = '',
}: PageWrapperProps) => {
	return (
		<Styled.Wrapper paragraph={paragraph} overflow={overflow}>
			{breadcrumbs && <Styled.PageBreadCrumbs paragraph="20px" />}
			{title && <Title label={title} paragraph="32px" />}
			{wrapper ? (
				<Styled.InnerWrapper direction="column" padding="24px">
					{children}
				</Styled.InnerWrapper>
			) : (
				children
			)}
		</Styled.Wrapper>
	);
};

export default PageWrapperComponent;

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { flexGap } from '@/shared/lib/helpers/flex-gag-polyfill.helper';

import { LinkProps } from './link.types';

export const Wrapper = styled(Link)<Pick<LinkProps, 'decoration' | 'color' | 'gap'>>`
	display: flex;
	text-decoration: ${({ decoration }) => decoration};
	text-decoration-color: ${({ color, theme: { text } }) => color && text.colors[color]};
	${({ gap }) => flexGap(String(gap))};
`;

import { createContext } from 'react';

export interface ThemeContextProps {
	timezone: string;
	setTimezone: (timezone: string) => void;
}

export const TimezoneContext = createContext<ThemeContextProps>({
	timezone: '',
	setTimezone: () => {},
});

import {
	bindActionCreators,
	createSlice as rtkCreateSlice,
	CreateSliceOptions,
	SliceCaseReducers,
} from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { StoreSchema } from '@/app/providers/store';
import { useAppDispatch } from '@/shared/lib/hooks/use-app-dispatch.hook';

type Selector<T, Args extends unknown[]> = (state: StoreSchema, ...args: Args) => T;
type Hook<T, Args extends unknown[]> = (...args: Args) => T;
type Result<T, Args extends unknown[]> = [Hook<T, Args>, Selector<T, Args>];

/**
 * A function that inherits the logic of useSelector but also forms a hook for our selector
 * @param {Selector<T, Args>} [selector]
 * @return Result<T, Args>
 * @example
 * const [useBoatById,selectBoatById] = createSelector((state, id: string) => selectBoat.selectById(state, id));
 */

const createSelector = <T, Args extends unknown[]>(selector: Selector<T, Args>): Result<T, Args> => {
	const useSelectorHook: Hook<T, Args> = (...args: Args) => {
		return useSelector((state: StoreSchema) => selector(state, ...args));
	};

	return [useSelectorHook, selector];
};

/**
 * A function that inherits the logic of rtkCreateSlice and creates hooks for our actions
 * @param {CreateSliceOptions<State, CaseReducers, Name>} [options]
 * @returns Slice<State, CaseReducers, Name> & {useActions: () => CaseReducerActions<CaseReducers, Name>}
 * @example
 * export const exampleSlice = createSlice({
 * 	name: 'example',
 * 	reducers: {
 * 	...
 * 	},
 * });
 *
 * export const { actions: exampleActions, reducer: exampleReducer, useActions: useExampleActions } = exampleSlice;
 */

const createSlice = <State, CaseReducers extends SliceCaseReducers<State>, Name extends string = string>(
	options: CreateSliceOptions<State, CaseReducers, Name>,
) => {
	const slice = rtkCreateSlice(options);

	const useActions = (): typeof slice.actions => {
		const dispatch = useAppDispatch();

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return useMemo(() => bindActionCreators(slice.actions, dispatch), [dispatch]);
	};

	return {
		...slice,
		useActions,
	};
};

export { createSelector, createSlice };

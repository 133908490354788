import { OFFLINE_LABEL } from '@/shared/constants/offline-label.constant';

export const transformSeconds = (seconds: number) => {
	if (seconds > 300) {
		return OFFLINE_LABEL;
	}

	if (seconds <= 0) {
		return '00:00';
	}

	const transformedSeconds = String(seconds.toFixed(2)).split('.').join(':');

	if (seconds < 10) {
		return '0' + transformedSeconds;
	}

	return transformedSeconds;
};

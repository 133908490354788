import { createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';

import { StoreSchema } from '@/app/providers/store';
import { createSlice } from '@/shared/lib/helpers/create-store-logic';
import { generateUid } from '@/shared/lib/helpers/generate-uid.helper';

import { Toast } from '../types/toast.interface';
import { ToastSchema } from '../types/toast.schema';

const toastAdapter = createEntityAdapter<Toast>({
	selectId: (toast) => toast.id,
	sortComparer: (a, b) => (Number(a.id) > Number(b.id) ? 1 : -1),
});

export const selectToast = toastAdapter.getSelectors<StoreSchema>(
	(state) => state.toast || toastAdapter.getInitialState(),
);

export const toastSlice = createSlice({
	name: 'toast',
	initialState: toastAdapter.getInitialState<ToastSchema>({ ids: [], entities: {} }),
	reducers: {
		addToast: (state, action: PayloadAction<Omit<Toast, 'id'>>) => {
			const id = generateUid();

			toastAdapter.addOne(state, { id, ...action.payload });
		},
		removeToast: toastAdapter.removeOne,
	},
});

export const { actions: toastActions, reducer: toastReducer, useActions: useToastActions } = toastSlice;

import styled, { css } from 'styled-components';

import { TooltipProps } from '@/shared/ui/tooltip/tooltip.types';

export const TooltipPopup = styled.div<Pick<TooltipProps, 'direction' | 'position' | 'flexDirection' | 'maxWidth'>>`
	display: flex;
	flex-direction: ${({ flexDirection }) => flexDirection};
	max-width: ${({ maxWidth }) => maxWidth};
	flex-wrap: ${({ maxWidth }) => (maxWidth ? 'wrap' : 'initial')};
	position: ${({ position }) => position};
	left: ${({ position }) => position === 'absolute' && '50%'};
	transform: ${({ position }) => (position === 'absolute' ? 'translateX(-50%)' : 'translateX(15%)')};
	font-size: 16px;
	padding: 8px 24px;
	border-radius: 2px;
	width: fit-content;
	background: ${({ theme: { tooltip } }) => tooltip.background};
	color: ${({ theme: { tooltip } }) => tooltip.color};
	border: ${({ theme: { tooltip } }) => tooltip.border};
	z-index: ${({ theme: { zIndex } }) => zIndex.modal};

	${({ direction }) =>
		direction === 'top' &&
		css`
			top: 0;
			transform: translateY(-100%) translateX(-50%);
		`} @media ${({ theme: { media } }) => media.tabletAndMobile} {
		width: fit-content;
	}
`;

export const SplittedTooltip = styled.div`
	white-space: normal;
`;

import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

type MediaQuery =
	| 'tablet'
	| 'mobile'
	| 'mobileAndLaptop'
	| 'tabletAndLaptop'
	| 'desktop'
	| 'desktopAndMobile'
	| 'desktopAndTablet'
	| 'mobileAndTablet'
	| 'tabletAndMobile';

/**
 * Hook to get information on what screen is now
 * @param {MediaQuery} [query] - Types of screens
 * @returns boolean - Returns true if the screen matches the selected type
 * @example
 * const isTablet = useMatchMedia('tabletAndMobile');
 *
 * return {!isTablet && <Sidebar />}
 */

export const useMatchMedia = (query: MediaQuery) => {
	const { media } = useTheme();
	const [matches, setMatches] = useState(window.matchMedia(media[query]).matches);

	useEffect(() => {
		const mediaQuery = window.matchMedia(media[query]);

		const handleChange = (event: MediaQueryListEvent) => {
			setMatches(event.matches);
		};

		if (mediaQuery?.addEventListener) {
			mediaQuery.addEventListener('change', handleChange);
		} else {
			mediaQuery.addListener(handleChange);
		}

		setMatches(mediaQuery.matches);

		return () => {
			if (mediaQuery?.removeEventListener) {
				mediaQuery.removeEventListener('change', handleChange);
			} else {
				mediaQuery.removeListener(handleChange);
			}
		};
	}, [media, query]);

	return matches;
};

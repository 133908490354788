import { WSEvent } from '@/shared/constants/websocket-event.constant';

export class WebSocketClient {
	socket: WebSocket;

	constructor(params: string, apiUrl = DASHBOARD_WS_API_URL) {
		this.socket = new WebSocket(`${apiUrl}/ws/${params}`);
		this.addMessageListener = this.addMessageListener.bind(this);
		this.addErrorListener = this.addErrorListener.bind(this);
		this.close = this.close.bind(this);
	}

	addMessageListener<Data>(updateDataCallback: (data: Data) => void) {
		const listener = (event: MessageEvent) => {
			const data = JSON.parse(event.data) as Data;

			updateDataCallback(data);
		};

		this.socket.addEventListener(WSEvent.Message, listener);
	}

	addErrorListener() {
		this.socket.addEventListener(WSEvent.Error, (err) => console.log(err));
	}

	close() {
		this.socket.close();
	}
}

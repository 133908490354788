const FormatType = {
	minutes: 60000,
	day: 60000 * 60 * 24,
};

export const subtractFromCurrentTime = (value: number, type: 'minutes' | 'day' = 'minutes') =>
	new Date(Date.now() - value * FormatType[type]);

const getPreviousDate = (days: number, type: 'start' | 'end' | 'default' = 'default') => {
	if (type === 'default') {
		return new Date(new Date().setDate(new Date().getDate() - days));
	}

	const hours = type === 'start' ? 0 : 23;
	const min = type === 'start' ? 0 : 59;
	const sec = type === 'start' ? 0 : 59;
	const ms = type === 'start' ? 0 : 999;
	return new Date(new Date(new Date().setDate(new Date().getDate() - days)).setHours(hours, min, sec, ms));
};

export const timeRanges = [
	{ label: 'Last 5 minutes', endDate: new Date(), startDate: subtractFromCurrentTime(5) },
	{ label: 'Last 15 minutes', endDate: new Date(), startDate: subtractFromCurrentTime(15) },
	{ label: 'Last 30 minutes', endDate: new Date(), startDate: subtractFromCurrentTime(30) },
	{ label: 'Last 1 hour', endDate: new Date(), startDate: subtractFromCurrentTime(60) },
	{ label: 'Last 3 hour', endDate: new Date(), startDate: subtractFromCurrentTime(180) },
	{ label: 'Last 6 hour', endDate: new Date(), startDate: subtractFromCurrentTime(360) },
	{ label: 'Last 12 hour', endDate: new Date(), startDate: subtractFromCurrentTime(720) },
	{ label: 'Last 24 hour', endDate: new Date(), startDate: subtractFromCurrentTime(1, 'day') },
	{ label: 'Last 2 days', endDate: new Date(), startDate: subtractFromCurrentTime(2, 'day') },
	{ label: 'Last 7 days', endDate: new Date(), startDate: subtractFromCurrentTime(7, 'day') },
	{ label: 'Last 30 days', endDate: new Date(), startDate: subtractFromCurrentTime(30, 'day') },
	{ label: 'Last 90 days', endDate: new Date(), startDate: subtractFromCurrentTime(90, 'day') },
	{ label: 'Last 6 months', endDate: new Date(), startDate: subtractFromCurrentTime(180, 'day') },
	{ label: 'Last 1 year', endDate: new Date(), startDate: subtractFromCurrentTime(365, 'day') },
	{ label: 'Last 2 years', endDate: new Date(), startDate: subtractFromCurrentTime(730, 'day') },
	{ label: 'Last 5 years', endDate: new Date(), startDate: subtractFromCurrentTime(1825, 'day') },
	{ label: 'Yesterday', endDate: getPreviousDate(1, 'end'), startDate: getPreviousDate(1, 'start') },
	{ label: 'Day before yesterday', endDate: getPreviousDate(2, 'end'), startDate: getPreviousDate(2, 'start') },
	{ label: 'This day last week', endDate: getPreviousDate(7, 'end'), startDate: getPreviousDate(7, 'start') },
	{ label: 'Previous week', endDate: new Date(), startDate: getPreviousDate(7) },
	{ label: 'Previous month', endDate: new Date(), startDate: getPreviousDate(30) },
	{ label: 'Previous fiscal quarter', endDate: new Date(), startDate: getPreviousDate(90) },
	{ label: 'Previous year', endDate: new Date(), startDate: getPreviousDate(365) },
	{ label: 'Previous fiscal year', endDate: new Date(), startDate: getPreviousDate(365) },
	{ label: 'Today', endDate: getPreviousDate(0, 'end'), startDate: getPreviousDate(0, 'start') },
	{ label: 'Today so far', endDate: new Date(), startDate: getPreviousDate(0, 'start') },
	{ label: 'This week', endDate: getPreviousDate(-7), startDate: new Date() },
	{ label: 'This month', endDate: getPreviousDate(-30), startDate: new Date() },
	{ label: 'This quarter', endDate: getPreviousDate(-90), startDate: new Date() },
	{ label: 'This year', endDate: getPreviousDate(-365), startDate: new Date() },
];

import { Component, ErrorInfo } from 'react';

import { Error } from '@/shared/ui/error';

import { ErrorBoundaryProps, ErrorBoundaryState } from './error-boundary.types';

class ErrorBoundaryComponent extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.log(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return <Error />;
		}

		return this.props.children;
	}
}

export default ErrorBoundaryComponent;

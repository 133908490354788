import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AuthorizationPage } from '@/pages/authorization';
import { CheckEmailPage } from '@/pages/check-email';
import { ForgotPasswordPage } from '@/pages/forgot-password';
import { LoginPage } from '@/pages/login';
import { NewPasswordPage } from '@/pages/new-password';
import { PasswordResetPage } from '@/pages/password-reset';
import { AppRoutes } from '@/shared/constants/app-routes.constant';
import { PageLoader } from '@/widgets/page';

const RouterComponent = () => {
	return (
		<Suspense fallback={<PageLoader />}>
			<Routes>
				<Route element={<AuthorizationPage />} path={AppRoutes.Home}>
					<Route index element={<Navigate to={AppRoutes.Login} replace />} />
					<Route element={<LoginPage />} path={AppRoutes.Login} />
					<Route element={<ForgotPasswordPage />} path={AppRoutes.ForgotPassword} />
					<Route element={<CheckEmailPage />} path={AppRoutes.CheckEmail} />
					<Route element={<NewPasswordPage />} path={AppRoutes.NewPassword} />
					<Route element={<PasswordResetPage />} path={AppRoutes.PasswordReset} />
				</Route>
				<Route element={<Navigate to={AppRoutes.Login} />} path="*" />
			</Routes>
		</Suspense>
	);
};

export default RouterComponent;

import { forwardRef, memo } from 'react';

import { Icon } from '@/shared/ui/icon';
import { Text } from '@/shared/ui/text';

import * as Styled from './link.styles';
import { LinkProps } from './link.types';

const LinkComponent = forwardRef<HTMLAnchorElement, LinkProps>(
	({ to, className, Svg, gap = '12px', label, size, color, fill, cursor, ...props }, ref) => {
		return (
			<Styled.Wrapper to={to} ref={ref} color={color} gap={gap} className={className} {...props}>
				{Svg && <Icon Svg={Svg} fill={fill} />}
				<Text label={label} weight={500} color={color} size={size} cursor={cursor} />
			</Styled.Wrapper>
		);
	},
);

export default memo(LinkComponent);

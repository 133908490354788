import { ChangeEvent, Dispatch, SetStateAction, useCallback, useDeferredValue, useMemo, useState } from 'react';

import { OptionList } from '@/shared/lib/interfaces/option.interface';

type UseSearchHook = (
	items: OptionList,
	showBeforeSearch?: boolean,
) => [string, OptionList, (e: ChangeEvent<HTMLInputElement>) => void, Dispatch<SetStateAction<string>>];

/**
 * Hook to create a filtering object with items
 * @param {OptionList} [items] - List of items as an object where the key is the id and the value is the name
 * @param {boolean} [showBeforeSearch=true] - It does show a list of available items, without entering
 * @return [string, [string, string][], (e: ChangeEvent<HTMLInputElement>) => void, Dispatch<SetStateAction<string>>]
 * @example
 * const [search, filteredOptions, onFilter, setSearch] = useSearch(boats);
 *
 * return <>
 *   <Input variant="secondary" Adornment={SearchSvg} value={search} maxWidth="l" onChange={setSearch} />
 *   {filteredOptions.map(([value, label]) => (
 * 		<Styled.Option key={value}>
 * 			...
 * 		</Styled.Option>
 * 		))}
 * </>
 */

export const useSearch: UseSearchHook = (items, showBeforeSearch = true) => {
	const [query, setQuery] = useState('');
	const deferredQuery = useDeferredValue(query);
	const filteredOptions = useMemo(() => {
		if (!showBeforeSearch && !deferredQuery) {
			return [];
		}

		return items.filter(({ label }) => label.toLowerCase().includes(deferredQuery.toLowerCase()));
	}, [showBeforeSearch, deferredQuery, items]);

	const onFilter = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setQuery(e.target.value);
		},
		[setQuery],
	);

	return [deferredQuery, filteredOptions, onFilter, setQuery];
};

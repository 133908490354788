import { BoatVariables, BoatVariablesResponse } from '../types/historical-data.interface';

export const parseBoatVariables = ({ Data, variable, Alarm, Status }: BoatVariablesResponse): BoatVariables => {
	return {
		data: Data
			? Data.map((data) => ({
					value: data.uuid,
					label: data.label,
			  }))
			: variable.map((data) => ({
					value: data.uuid,
					label: data.label,
			  })),
		alarms: Alarm
			? Alarm.map((alarm) => ({
					value: alarm.uuid,
					label: alarm.label,
			  }))
			: [],
		statuses: Status
			? Status.map((status) => ({
					value: status.uuid,
					label: status.label,
			  }))
			: [],
	};
};

import styled from 'styled-components';

import { Autocomplete } from '@/shared/ui/autocomplete';

export const SelectVariables = styled(Autocomplete)<{ label?: string }>`
	width: ${({ theme: { autocomplete } }) => autocomplete.width.l};
	margin-top: ${({ label }) => !label && '23px'};

	@media ${({ theme: { media } }) => media.mobile} {
		width: ${({ theme: { autocomplete } }) => autocomplete.width.full};
	}
`;

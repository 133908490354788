interface TimeZoneFormatResponse {
	label: string;
	offset: number;
}

const getLongOffsetFormat = (offsetShort: string): string => {
	switch (offsetShort?.length) {
		case 2: {
			return `${offsetShort[0]}0${offsetShort[1]}:00`;
		}
		case 3: {
			return `${offsetShort}:00`;
		}
		case 5: {
			return `${offsetShort[0]}0${offsetShort.slice(1, offsetShort?.length)}`;
		}
		case 6: {
			return offsetShort;
		}
		default: {
			return '';
		}
	}
};

const getOffset = (offsetFull?: string): number => {
	if (!offsetFull) {
		return 0;
	}

	const offset = getLongOffsetFormat(offsetFull);

	const [hours, minutes] = offset.split(':');

	return Number(hours) + Number(minutes) / 60;
};

export const timezoneFormat = (timeZone: string, onlyOffset = false): TimeZoneFormatResponse => {
	const offsetFull = getLongOffsetFormat(
		Intl.DateTimeFormat('en', { timeZone, timeZoneName: 'short' })
			.formatToParts()
			.find((part) => part.type === 'timeZoneName')
			?.value?.slice(3) || '+00:00',
	);
	const offset = getOffset(offsetFull);

	if (onlyOffset) {
		return { label: `(UTC${offsetFull})`, offset };
	}

	return { label: `(UTC${offsetFull}) ${timeZone}`, offset };
};

import { Combobox } from '@headlessui/react';
import styled, { css } from 'styled-components';

import { Box } from '@/shared/ui/box';
import { Caption } from '@/shared/ui/caption';
import { Icon } from '@/shared/ui/icon';

import { AutocompleteProps, StyledOptional, StyledSearchInOptions } from './autocomplete.types';

export const optionsCss = css`
	padding: 12px 16px;
	outline: none;
	border-top: ${({ theme: { option } }) => option.border};
	border-bottom: ${({ theme: { option } }) => option.border};
	color: ${({ theme: { option } }) => option.color};
	background-color: ${({ theme: { option } }) => option.background};
	font-weight: 500;
	cursor: pointer;
	transition: ${({ theme: { transition } }) => transition.primary};

	&:hover {
		background-color: ${({ theme: { option } }) => option.hover};
	}
`;

export const Input = styled(Combobox.Input)<
	Required<Pick<AutocompleteProps<string>, 'variant' | 'paragraph'>> & StyledOptional
>`
	outline: none;
	font-size: 15px;
	padding: ${({ adornment }) => (adornment ? '8px 16px 8px 42px' : '8px 16px')};
	border: 1px solid ${({ variant, theme: { input } }) => input[variant].border};
	background-color: ${({ variant, theme: { input } }) => input[variant].background};
	color: ${({ variant, theme: { input } }) => input[variant].fontColor};
	border-radius: ${({ variant, theme: { input } }) => input[variant].radius};
	margin-bottom: ${({ paragraph }) => paragraph};
	width: 100%;

	&:focus {
		border: 1px solid ${({ variant, theme: { input } }) => input[variant].focus};
	}
`;

export const Options = styled(Combobox.Options)<StyledOptional>`
	outline: none;
	width: 100%;
	position: absolute;
	top: 100%;
	overflow-y: ${({ scroll }) => (scroll ? 'scroll' : 'hidden')};
	min-height: ${({ scroll }) => (scroll ? '230px' : 'hidden')};
	max-height: ${({ scroll }) => (scroll ? '230px' : 'hidden')};
	overflow-x: hidden;
	z-index: ${({ theme: { zIndex } }) => zIndex.menu};
	border: ${({ border, theme: { option } }) => (border ? option.border : 'none')};
`;

export const Option = styled(Combobox.Option)`
	${optionsCss}
`;

export const NoMatchesOption = styled.li`
	${optionsCss}
`;

export const Adornment = styled(Icon)`
	position: absolute;
	left: 17px;
	top: 25%;
`;

export const Selected = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const Label = styled(Caption)`
	margin-bottom: 8px;
`;

export const SearchInput = styled(Input)`
	padding: 8px 16px 8px 42px;
	background-color: ${({ theme: { option } }) => option.background};

	&:focus {
		border: 1px solid ${({ variant, theme: { input } }) => input[variant].border};
	}
`;

export const SearchIcon = styled(Icon)`
	position: absolute;
	z-index: 5;
	left: 17px;
	top: 25%;
`;

export const SearchWrapper = styled(Box)`
	position: sticky;
	top: 0;
`;

export const Button = styled(Combobox.Button)<
	Required<Pick<AutocompleteProps<string>, 'variant' | 'arrow'>> & StyledOptional & StyledSearchInOptions
>`
	width: 100%;
	border: none;
	outline: none;
	background: none;
	white-space: pre-wrap;
	padding-right: 24px;
	min-height: 37px;
	height: 100%;

	${({ arrow }) =>
		arrow &&
		css`
			background-image: url(${({ theme: { select } }) => select.arrow.open});
			background-repeat: no-repeat;
			background-position: calc(100% - 16px) center;
			background-size: 12px;
			cursor: pointer;

			&[aria-expanded='true'] {
				border: 1px solid ${({ variant, theme: { input } }) => input[variant].focus};
				background-image: url(${({ theme: { select } }) => select.arrow.close});
			}
		`}

	${({ searchinoptions, variant, open }) =>
		searchinoptions &&
		css`
			padding: 8px 16px;
			text-align: left;
			cursor: pointer;
			border: 1px solid ${({ theme: { input } }) => (open ? input[variant].focus : input[variant].border)};
			background-color: ${({ theme: { input } }) => input[variant].background};
			color: ${({ theme: { input } }) => input[variant].fontColor};
			font-size: 15px;
			border-radius: ${({ theme: { input } }) => input[variant].radius};
			margin-right: 24px;
		`}
`;

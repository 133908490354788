//DARK THEME COLORS

export const ORANGE_1 = '#F07843';
export const ORANGE_2 = '#F38759';
export const ORANGE_3 = '#F6956C';
export const ORANGE_4 = '#F9A37F';
export const ORANGE_5 = '#FBB092';
export const ORANGE_6 = '#FDBEA4';
export const ORANGE_7 = '#FECBB6';
export const ORANGE_8 = '#FFD8C8';
export const ORANGE_9 = '#FFE5DA';
export const ORANGE_10 = '#FFF2ED';

export const DARK_1 = '#000';
export const DARK_2 = '#0d1015';
export const DARK_3 = '#0D1116';
export const DARK_4 = '#161C23';
export const DARK_5 = '#292E33';

export const DARK_6 = '#1A1F25';

//LIGHT THEME COLORS

export const BLUE_1 = '#265b77';
export const BLUE_2 = '#3594B4';
export const BLUE_3 = '#51A0BD';
export const BLUE_4 = '#69ABC5';
export const BLUE_5 = '#80B7CE';
export const BLUE_6 = '#95C3D6';
export const BLUE_7 = '#ABCFDE';
export const BLUE_8 = '#C0DBE6';
export const BLUE_9 = '#D5E7EF';
export const BLUE_10 = '#EAF3F7';
export const BLUE_11 = '#F6FCFF';
export const BLUE_12 = '#005C7A';

//COMMON COLORS

export const ERROR = '#FF5761';
export const WARNING = '#F0B152';
export const SUCCESS = '#36C555';
export const WHITE = '#FFF';
export const GRAY_1 = '#3F3F3F';
export const GRAY_2 = '#505050';
export const GRAY_3 = '#616161';
export const GRAY_4 = '#747474';
export const GRAY_5 = '#868686';
export const GRAY_6 = '#999999';
export const GRAY_7 = '#ADADAD';
export const GRAY_8 = '#C1C1C1';
export const GRAY_9 = '#D5D5D5';
export const GRAY_10 = '#EAEAEA';
export const GRAY_11 = '#F8F8F8';
export const GRAY_12 = '#444141';

export const SIDEBAR = '#265b77';

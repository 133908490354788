import { Navigate } from 'react-router-dom';

import { UserRole, useUserRole } from '@/entities/user';
import { AppRoutes } from '@/shared/constants/app-routes.constant';

import { RoleElementProps } from './role-element.types';

const RoleElementComponent = ({ children, roles = [UserRole.Admin] }: RoleElementProps) => {
	const isRolesMatches = useUserRole(roles);

	if (isRolesMatches) {
		return <>{children}</>;
	}

	return <Navigate to={AppRoutes.Home} />;
};

export default RoleElementComponent;

import { memo, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import ArrowSvg from '@/shared/assets/icons/ui-kit/breadcrumb-arrow.svg';
import GlobSvg from '@/shared/assets/icons/ui-kit/breadcrumb-glob.svg';
import { getLabel } from '@/shared/ui/breadcrumbs/breadcrumbs.data';

import * as Styled from './breadcrumbs.styles';
import { BreadcrumbReduce, BreadcrumbsProps } from './breadcrumbs.types';

const BreadcrumbsComponent = ({ className, paragraph }: BreadcrumbsProps) => {
	const { pathname } = useLocation();

	const crumbs = useMemo(
		() =>
			pathname
				.split('/')
				.filter((path) => Boolean(path) && !Number(path))
				.reduce<BreadcrumbReduce[]>(
					(crumbs, crumb, index) =>
						index
							? [...crumbs, { to: `${crumbs[index - 1].to}/${crumb}`, label: getLabel(crumb), Svg: ArrowSvg }]
							: [{ to: `/${crumb}`, label: getLabel(crumb), Svg: GlobSvg }],
					[],
				),
		[pathname],
	);

	return (
		<Styled.Wrapper className={className} marginBottom={paragraph} tag="nav" align="center">
			{crumbs.map(({ to, label, Svg }, index) => (
				<Styled.BreadcrumbLink active={index === crumbs.length - 1} key={to} to={to} label={label} Svg={Svg} />
			))}
		</Styled.Wrapper>
	);
};

export default memo(BreadcrumbsComponent);

import { convertTimezone } from '@/shared/lib/helpers/convert-timezone.helper';
import { dateFormat } from '@/shared/lib/helpers/date-format.helper';

import { DownloadHistoricalDataResponse } from '../types/historical-data.interface';

export const transformHistoricalDataResponse = (response: DownloadHistoricalDataResponse[], timezone: string) =>
	response.map(({ id, status, url, job_uuid, start_time, end_time, file_format, created_at }) => ({
		id,
		status,
		url,
		start: start_time ? dateFormat(convertTimezone(start_time, timezone)) : ' ',
		end: end_time ? dateFormat(convertTimezone(end_time, timezone)) : ' ',
		jobUuid: job_uuid,
		fileFormat: file_format,
		createdAt: created_at ? dateFormat(convertTimezone(created_at, timezone)) : ' ',
	}));

import { memo } from 'react';

import * as Styled from './icon.styles';
import { IconProps } from './icon.types';

const IconComponent = ({ Svg, roundWrapper, size, ...props }: IconProps) => {
	if (roundWrapper) {
		return (
			<Styled.RoundWrapper size={size} roundWrapper={roundWrapper}>
				<Svg {...props} />
			</Styled.RoundWrapper>
		);
	}

	return <Svg {...props} />;
};

export default memo(IconComponent);

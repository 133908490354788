import { memo } from 'react';

import { Spinner } from '@/shared/ui/spinner';

import * as Styled from './page-loader.styles';

const PageLoaderComponent = () => {
	return (
		<Styled.Wrapper tag="main" align="center" justify="center" flex={1}>
			<Spinner />
		</Styled.Wrapper>
	);
};

export default memo(PageLoaderComponent);
